import React, { useState, useEffect } from "react";
import MiniDrawer from "../components/Minidrawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
// import styles from "../styles/Avg_TTF.module.css";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import styles from "../Styles/Average_ttf.module.css"
import report_loader from "../Media/ezgif.com-optimize.gif";

function Average_TTF_Dashboard() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

  const format = moment().subtract(1, "years").format("YYYY");              
  const dateFormat = "YYYY";
  
// const [selectYear, setSelectYear] = useState(dayjs().subtract(1, "year"));
const [selectYear, setSelectYear] = useState(dayjs());
// const [sendYear, SetSendYear] = useState(moment().subtract(1, "years").format("YYYY"));
const [sendYear, SetSendYear] = useState(moment().format("YYYY"));
  const [load, setLoad] = useState(false);
  const [avg_ttf_data, setAvg_ttf_data] = useState([]);

  // data
  const data = [
    {
      month: "January 2023",
      FT_noOfClosure: 5,
      FT_AvgTTF: 23,
      PT_noOfClosure: 6,
      PT_AvgTTF: 33,
      Co_noOfClosure: 9,
      Co_AvgTTF: 13,
      In_noOfClosure: 2,
      In_AvgTTF: 13,
    },
    {
      month: "September 2023",
      FT_noOfClosure: 2,
      FT_AvgTTF: 20,
      PT_noOfClosure: 6,
      PT_AvgTTF: 23,
      Co_noOfClosure: 5,
      Co_AvgTTF: 12,
      In_noOfClosure: 2,
      In_AvgTTF: 20,
    },
    {
      month: "November 2023",
      FT_noOfClosure: 6,
      FT_AvgTTF: 24,
      PT_noOfClosure: 16,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 19,
      In_noOfClosure: 2,
      In_AvgTTF: 43,
    },
    {
      month: "May 2022",
      FT_noOfClosure: 16,
      FT_AvgTTF: 24,
      PT_noOfClosure: 10,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 9,
      In_noOfClosure: 12,
      In_AvgTTF: 3,
    },
    {
      month: "September 2021",
      FT_noOfClosure: 6,
      FT_AvgTTF: 4,
      PT_noOfClosure: 16,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 19,
      In_noOfClosure: 2,
      In_AvgTTF: 43,
    },
    {
      month: "January 2024",
      FT_noOfClosure: 5,
      FT_AvgTTF: 23,
      PT_noOfClosure: 6,
      PT_AvgTTF: 33,
      Co_noOfClosure: 9,
      Co_AvgTTF: 13,
      In_noOfClosure: 2,
      In_AvgTTF: 13,
    },
    {
      month: "August 2023",
      FT_noOfClosure: 5,
      FT_AvgTTF: 23,
      PT_noOfClosure: 6,
      PT_AvgTTF: 33,
      Co_noOfClosure: 9,
      Co_AvgTTF: 13,
      In_noOfClosure: 2,
      In_AvgTTF: 13,
    },
    {
      month: "December 2021",
      FT_noOfClosure: 2,
      FT_AvgTTF: 20,
      PT_noOfClosure: 6,
      PT_AvgTTF: 23,
      Co_noOfClosure: 5,
      Co_AvgTTF: 12,
      In_noOfClosure: 2,
      In_AvgTTF: 20,
    },
    {
      month: "February 2022",
      FT_noOfClosure: 6,
      FT_AvgTTF: 24,
      PT_noOfClosure: 16,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 19,
      In_noOfClosure: 2,
      In_AvgTTF: 43,
    },
    {
      month: "October 2023",
      FT_noOfClosure: 16,
      FT_AvgTTF: 24,
      PT_noOfClosure: 10,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 9,
      In_noOfClosure: 12,
      In_AvgTTF: 3,
    },
    {
      month: "November 2023",
      FT_noOfClosure: 6,
      FT_AvgTTF: 4,
      PT_noOfClosure: 16,
      PT_AvgTTF: 35,
      Co_noOfClosure: 7,
      Co_AvgTTF: 19,
      In_noOfClosure: 2,
      In_AvgTTF: 43,
    },
    {
      month: "August 2021",
      FT_noOfClosure: 5,
      FT_AvgTTF: 23,
      PT_noOfClosure: 6,
      PT_AvgTTF: 33,
      Co_noOfClosure: 9,
      Co_AvgTTF: 13,
      In_noOfClosure: 2,
      In_AvgTTF: 13,
    },
  ];

  // initializations
  // let dateFormat = "YYYY";
  // let format2 = moment("2023").format("YYYY");
  // const [year , setYear] = useState(new Date("2023"));
  // const [direction, setDirection] = useState('desc')

  //   const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  //   const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  //   //handles page change happens during pagination
  //  const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // // handles rows displayed based on pagination
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  // changes the sort direction on click of table head "Month"
  // const handleDirection = () =>{
  //   const isDesc = direction==='desc';
  //   setDirection(isDesc ? 'asc' : 'desc');
  // }

  /// function to sort data based on month Year
  //  const MonthSort = (array) => {
  //   const sortedData = array.sort((a,b)=>{
  //     const monthA = moment(a.month,"MMMM YYYY");
  //     const monthB = moment(b.month,"MMMM YYYY");
  //     if(direction==='desc'){
  //       return monthB-monthA;
  //     }else{
  //       return  monthA-monthB;
  //     }
  //   })
  //     return sortedData;
  // }

  // handles selection of year in datepicker
  // const handleYearChange = (date,dateString) => {
  //   if(date !==null){
  //     setYear(date)
  //   }
  // }

  /// useMemo will render everytime one of the dependencies changes
  //hence when direction property changes the data will be rendered everytime
  // same for page and rowPerPage

  // const visibleRows = React.useMemo(
  //   () =>
  //     MonthSort(data),
  //     // .slice(
  //     //   page * rowsPerPage,
  //     //   page * rowsPerPage + rowsPerPage,
  //     // ),
  //   [direction],
  // );

  const getAvgTTFData = async () => {
    setLoad(true);
    try {
      let res = await fetch(
        `https://venus.equinoxlab.com/api/Average_TTF.svc/Average_TTF_Dashboard/${sendYear}`
      );
      let data = await res.json();
      setAvg_ttf_data(data.data);
      setLoad(false);
    } catch (error) {
      ;
    }
  };

  const onYearChange = (date, dateString) => {
    if (date) {
      SetSendYear(dateString);
      setSelectYear(date);
    } else {
      SetSendYear(moment().subtract(1, "years").format("YYYY"));
      setSelectYear(dayjs().subtract(1, "year"));
    }
  };

  useEffect(() => {
    if(Role_id != ""){
      getAvgTTFData();
    }
  }, [sendYear]);

  return (
    <>
   <MiniDrawer/>
      <div
        className="container-fluid pt-2"
      >
        {load ? (
          <>
            {/* <Loader /> */}
            <img src={report_loader} alt="" id={styles.loader} />
          </>
        ) : (
          <>
     <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
           sx={{ maxHeight: "70vh" }}
                id={styles.offerSentTable}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead 
                   sx={{
                    backgroundColor: "#007cc3",
                    color: "#444a4f !important",
                    position: "sticky",
                    top: 0,
                    zIndex: "999",
                  }}
                  >
                       <TableRow>
                       <TableCell colSpan={8} >
                        <h5 style ={{padding :"10px 0px 10px 5px",}}>Average TTF Dashboard</h5>
                       </TableCell>
                       <TableCell  >
                       <Space direction="vertical">
                       <DatePicker
                  onChange={onYearChange}
                  picker="year"
                  value={selectYear}
                  defaultValue={dayjs(format, dateFormat)}
                />
              </Space>
                       </TableCell>
                       </TableRow>
                    <TableRow>
                      <TableCell
                        id={styles.ceo}
                        rowSpan={3}
                        style={{ width: "170px" }}
                      >
                         <h6 className="h6 mt-2" style={{ color: "white" }}>Month</h6>
                        
                      </TableCell>

                      <TableCell colspan={8} id={styles.ceo}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Employee Type</h6>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Full Time</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Part Time</h6>
   
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Intern</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Contract</h6>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>No.of Closure</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Average TTF (Days)</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>No.of Closure</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Average TTF (Days)</h6>
                  
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>No.of Closure</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Average TTF (Days)</h6>
             
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>No.of Closure</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Average TTF (Days)</h6>
          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {avg_ttf_data.length > 0 ? (
                      avg_ttf_data.map((ele, id) => (
                        <TableRow>
                        <TableCell id={styles.ceo}>{ele.Slot}</TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.No_Of_Closure_FT}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.FT_TTF}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.No_Of_Closure_PT}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.PT_TTF}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.No_Of_Closure_INT}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.INT_TTF}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.No_Of_Closure_CT}
                        </TableCell>
                        <TableCell id={styles.ceo}>
                          {ele.CT_TTF}
                        </TableCell>
                      </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="center"
                          id={styles.tabledata}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                sx={{ maxHeight: "70vh" }}
                id={styles.offerSentTable}
              >
                <Table>
                  <TableHead  style={{ position: "sticky", top: 0 }}>
                  <TableRow>
                       <TableCell  colSpan={8} >
                        <h5 style ={{padding :"10px 0px 10px 5px"}}>Average TTF Dashboard</h5>
                       </TableCell>
                       <TableCell   >
                       <Space direction="vertical">
                       <DatePicker
                  onChange={onYearChange}
                  picker="year"
                  value={selectYear}
                  defaultValue={dayjs(format, dateFormat)}
                />
              </Space>
                       </TableCell>
                       </TableRow>
                    <TableRow>
                      <TableCell
           
           id={styles.th_unit_body}
                        rowSpan={3}
                        style={{ width: "170px",borderBottom: "1px solid white" }}
                      >
                        Month
                      </TableCell>

                      <TableCell  style={{ borderBottom: "1px solid white" }} colspan={8} id={styles.th_unit_body}>
                        Employee Type
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "1px solid white" }} id={styles.th_unit_body} colSpan={2}>
                        Full Time
                      </TableCell>
                      <TableCell style={{ borderBottom: "1px solid white" }} id={styles.th_unit_body} colSpan={2}>
                        Part Time
                      </TableCell>
                      <TableCell style={{ borderBottom: "1px solid white" }} id={styles.th_unit_body} colSpan={2}>
                        Intern
                      </TableCell>
                      <TableCell style={{ borderBottom: "1px solid white" }} id={styles.th_unit_body} colSpan={2}>
                        Contract
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        No.of Closure
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        Average TTF (Days)
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        No.of Closure
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        Average TTF (Days)
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        No.of Closure
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        Average TTF (Days)
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        No.of Closure
                      </TableCell>
                      <TableCell   id={styles.th_unit_body} style={{ width: "120px",borderBottom: "1px solid white" }}>
                        Average TTF (Days)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {avg_ttf_data.length > 0 ? (
                      avg_ttf_data.map((ele, id) => (
                        <TableRow>
                          <TableCell id={styles.ceo}>{ele.Slot}</TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.No_Of_Closure_FT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.FT_TTF}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.No_Of_Closure_PT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.PT_TTF}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.No_Of_Closure_INT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.INT_TTF}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.No_Of_Closure_CT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.CT_TTF}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="center"
                          id={styles.tabledata}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper> */}
          </>
        )}
      </div>
    </>

  
  );
}

export default Average_TTF_Dashboard;
