import {
  CATEGORY_COUNT_ERROR,
  CATEGORY_COUNT_REQUEST,
  CATEGORY_COUNT_SUCCESS,
  CATEGORY_DATA_MARKETING_ERROR,
  CATEGORY_DATA_MARKETING_REQUEST,
  CATEGORY_DATA_MARKETING_SUCCESS,
  CATEGORY_REVENUE_MARKETING_ERROR,
  CATEGORY_REVENUE_MARKETING_REQUEST,
  CATEGORY_REVENUE_MARKETING_SUCCESS,
  CATEGORY_UPDATED_ON_MARKETING_ERROR,
  CATEGORY_UPDATED_ON_MARKETING_REQUEST,
  CATEGORY_UPDATED_ON_MARKETING_SUCCESS,
  GET_BDE_CALLS_ERROR,
  GET_BDE_CALLS_REQUEST,
  GET_BDE_CALLS_SUCCESS,
  GET_BDE_LEADS_ERROR,
  GET_BDE_LEADS_REQUEST,
  GET_BDE_LEADS_SUCCESS,
  GET_BDE_MEETINGS_ERROR,
  GET_BDE_MEETINGS_REQUEST,
  GET_BDE_MEETINGS_SUCCESS,
  GET_CHECK_BDE_ERROR,
  GET_CHECK_BDE_REQUEST,
  GET_CHECK_BDE_SUCCESS,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_TYPE_REVENUE_ERROR,
  GET_CLIENT_TYPE_REVENUE_REQUEST,
  GET_CLIENT_TYPE_REVENUE_SUCCESS,
  GET_CLIENT_TYPE_SAMPLE_ERROR,
  GET_CLIENT_TYPE_SAMPLE_REQUEST,
  GET_CLIENT_TYPE_SAMPLE_REVENUE_ERROR,
  GET_CLIENT_TYPE_SAMPLE_REVENUE_REQUEST,
  GET_CLIENT_TYPE_SAMPLE_REVENUE_SUCCESS,
  GET_CLIENT_TYPE_SAMPLE_SUCCESS,
  GET_DAILY_ERROR,
  GET_DAILY_REQUEST,
  GET_DAILY_SUCCESS,
  GET_INVOICE_S6_ERROR,
  GET_INVOICE_S6_REQUEST,
  GET_INVOICE_S6_SUCCESS,
  GET_MONTHLY_DATA_ERROR,
  GET_MONTHLY_DATA_REQUEST,
  GET_MONTHLY_DATA_SUCCESS,
  GET_MONTHLY_ERROR,
  GET_MONTHLY_NON_TESTING_ERROR,
  GET_MONTHLY_NON_TESTING_REQUEST,
  GET_MONTHLY_NON_TESTING_SALES_COUNT_ERROR,
  GET_MONTHLY_NON_TESTING_SALES_COUNT_REQUEST,
  GET_MONTHLY_NON_TESTING_SALES_COUNT_SUCCESS,
  GET_MONTHLY_NON_TESTING_SUCCESS,
  GET_MONTHLY_PIECHART_COUNT_ERROR,
  GET_MONTHLY_PIECHART_COUNT_REQUEST,
  GET_MONTHLY_PIECHART_COUNT_SUCCESS,
  GET_MONTHLY_PIECHART_ERROR,
  GET_MONTHLY_PIECHART_REQUEST,
  GET_MONTHLY_PIECHART_SUCCESS,
  GET_MONTHLY_REQUEST,
  GET_MONTHLY_SALES_COUNT_ERROR,
  GET_MONTHLY_SALES_COUNT_REQUEST,
  GET_MONTHLY_SALES_COUNT_SUCCESS,
  GET_MONTHLY_SALES_ERROR,
  GET_MONTHLY_SALES_REQUEST,
  GET_MONTHLY_SALES_SUCCESS,
  GET_MONTHLY_SUCCESS,
  GET_MONTHLY_TESTING_ERROR,
  GET_MONTHLY_TESTING_REQUEST,
  GET_MONTHLY_TESTING_SALES_COUNT_ERROR,
  GET_MONTHLY_TESTING_SALES_COUNT_REQUEST,
  GET_MONTHLY_TESTING_SALES_COUNT_SUCCESS,
  GET_MONTHLY_TESTING_SUCCESS,
  GET_QC_DATA_ERROR,
  GET_QC_DATA_REQUEST,
  GET_QC_DATA_SUCCESS,
  GET_QUATERLY_NON_TESTING_ERROR,
  GET_QUATERLY_NON_TESTING_REQUEST,
  GET_QUATERLY_NON_TESTING_SALES_COUNT_ERROR,
  GET_QUATERLY_NON_TESTING_SALES_COUNT_REQUEST,
  GET_QUATERLY_NON_TESTING_SALES_COUNT_SUCCESS,
  GET_QUATERLY_NON_TESTING_SUCCESS,
  GET_QUATERLY_PIECHART_ERROR,
  GET_QUATERLY_PIECHART_REQUEST,
  GET_QUATERLY_PIECHART_SUCCESS,
  GET_QUATERLY_SALES_COUNT_ERROR,
  GET_QUATERLY_SALES_COUNT_REQUEST,
  GET_QUATERLY_SALES_COUNT_SUCCESS,
  GET_QUATERLY_SALES_ERROR,
  GET_QUATERLY_SALES_REQUEST,
  GET_QUATERLY_SALES_SUCCESS,
  GET_QUATERLY_TESTING_ERROR,
  GET_QUATERLY_TESTING_REQUEST,
  GET_QUATERLY_TESTING_SALES_COUNT_ERROR,
  GET_QUATERLY_TESTING_SALES_COUNT_REQUEST,
  GET_QUATERLY_TESTING_SALES_COUNT_SUCCESS,
  GET_QUATERLY_TESTING_SUCCESS,
  GET_REPORTING_PERSON_ERROR,
  GET_REPORTING_PERSON_REQUEST,
  GET_REPORTING_PERSON_SUCCESS,
  GET_SALESPERSON_CLIENT_TYPE_COUNT_ERROR,
  GET_SALESPERSON_CLIENT_TYPE_COUNT_REQUEST,
  GET_SALESPERSON_CLIENT_TYPE_COUNT_SUCCESS,
  GET_SALESPERSON_CLIENT_TYPE_REVENUE_ERROR,
  GET_SALESPERSON_CLIENT_TYPE_REVENUE_REQUEST,
  GET_SALESPERSON_CLIENT_TYPE_REVENUE_SUCCESS,
  GET_SERVICE_PENETRATION_ERROR,
  GET_SERVICE_PENETRATION_REQUEST,
  GET_SERVICE_PENETRATION_SUCCESS,
  GET_TESTING_LOCATION_ERROR,
  GET_TESTING_LOCATION_GRAPH_ERROR,
  GET_TESTING_LOCATION_GRAPH_REQUEST,
  GET_TESTING_LOCATION_GRAPH_SUCCESS,
  GET_TESTING_LOCATION_REQUEST,
  GET_TESTING_LOCATION_SUCCESS,
  GET_TRANSACTION_ERROR,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_USER_LIST_ERROR,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_WEEKLY_CLIENT_ERROR,
  GET_WEEKLY_CLIENT_REQUEST,
  GET_WEEKLY_CLIENT_SUCCESS,
  GET_YEARLY_NON_TESTING_ERROR,
  GET_YEARLY_NON_TESTING_REQUEST,
  GET_YEARLY_NON_TESTING_SALES_COUNT_ERROR,
  GET_YEARLY_NON_TESTING_SALES_COUNT_REQUEST,
  GET_YEARLY_NON_TESTING_SALES_COUNT_SUCCESS,
  GET_YEARLY_NON_TESTING_SUCCESS,
  GET_YEARLY_PIECHART_COUNT_ERROR,
  GET_YEARLY_PIECHART_COUNT_REQUEST,
  GET_YEARLY_PIECHART_COUNT_SUCCESS,
  GET_YEARLY_PIECHART_ERROR,
  GET_YEARLY_PIECHART_REQUEST,
  GET_YEARLY_PIECHART_SUCCESS,
  GET_YEARLY_SALES_ERROR,
  GET_YEARLY_SALES_REQUEST,
  GET_YEARLY_SALES_SUCCESS,
  GET_YEARLY_TESTING_ERROR,
  GET_YEARLY_TESTING_REQUEST,
  GET_YEARLY_TESTING_SALES_COUNT_ERROR,
  GET_YEARLY_TESTING_SALES_COUNT_REQUEST,
  GET_YEARLY_TESTING_SALES_COUNT_SUCCESS,
  GET_YEARLY_TESTING_SUCCESS,
  MARKETING_PERFORMANCE_ERROR,
  MARKETING_PERFORMANCE_REQUEST,
  MARKETING_PERFORMANCE_SUCCESS,
  SEND_GRAPH_MAIL_ERROR,
  SEND_GRAPH_MAIL_REQUEST,
  SEND_GRAPH_MAIL_SUCCESS,
} from "./actionTypes";

import Swal from "sweetalert2";
import moment from "moment";

let base_url = "https://reportapi.equinoxlab.com";

//  Transaction Report

export const transaction_req = () => ({
  type: GET_TRANSACTION_REQUEST,
});
export const transaction_succ = (data) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: data,
});
export const transaction_err = () => ({
  type: GET_TRANSACTION_ERROR,
});

export const get_transaction_data =
  (
    setTransaction_Data,
    user,
    department,
    invoice_start_date,
    invoice_end_date
  ) =>
  async (dispatch) => {
    let new_start_date =
      invoice_start_date !== null
        ? moment(invoice_start_date?.$d).format("YYYY-MM-DD")
        : "";
    let new_end_date =
      invoice_end_date !== null
        ? moment(invoice_end_date?.$d).format("YYYY-MM-DD")
        : "";
    try {
      dispatch(transaction_req());
      let res = await fetch(`${base_url}/transaction_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          username: user,
          start_date: new_start_date,
          end_date: new_end_date,
          dept_id: department,
        }),
      });
      let data = await res.json();

      if (data.response.code == 200) {
        dispatch(transaction_succ(data?.Data));
        setTransaction_Data(data?.Data);
      } else {
        setTransaction_Data([]);
        dispatch(transaction_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setTransaction_Data([]);
      dispatch(transaction_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//invoice_creation_daily_report

export const daily_report_req = () => ({
  type: GET_DAILY_REQUEST,
});
export const daily_report_succ = (data) => ({
  type: GET_DAILY_SUCCESS,
  payload: data,
});
export const daily_report_err = () => ({
  type: GET_DAILY_ERROR,
});

export const daily_invoice_report =
  (setDaily_Data,setTotal_Amt) => async (dispatch) => {
    try {
      dispatch(daily_report_req());
      let res = await fetch(`${base_url}/invoice_creation_daily_report`);
      let data = await res.json();
      if (data.response.code == 200) {
        dispatch(daily_report_succ(data?.Data));
        let total_amt =0;
        for(let i=0; i<data?.Data?.length; i++){
          total_amt+=data?.Data[i]?.total_amount
        }
        setTotal_Amt(total_amt)
        setDaily_Data(data?.Data);
      } else {
        dispatch(daily_report_err());
        setDaily_Data([]);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setDaily_Data([]);
      dispatch(daily_report_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//invoice_creation_monthly_report

export const monthly_report_req = () => ({
  type: GET_MONTHLY_REQUEST,
});
export const monthly_report_succ = (data) => ({
  type: GET_MONTHLY_SUCCESS,
  payload: data,
});
export const monthly_report_err = () => ({
  type: GET_MONTHLY_ERROR,
});

export const monthly_invoice_report =
  (setMonthly_Data,send_month,send_year,selected_salesperson,setTotal_Amt) =>
  async (dispatch) => {
    // let new_start_date =
    //   invoice_start_date !== null
    //     ? moment(invoice_start_date?.$d).format("YYYY-MM-DD")
    //     : "";
    // let new_end_date =
    //   invoice_end_date !== null
    //     ? moment(invoice_end_date?.$d).format("YYYY-MM-DD")
    //     : "";
    try {
      dispatch(monthly_report_req());
      let res = await fetch(`${base_url}/invoice_creation_monthly_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          month_input:send_month,
          year_input: send_year,
          salesperson_input:selected_salesperson
        }),
      });
      let data = await res.json();

      if (data.response.code == 200) {
        dispatch(monthly_report_succ(data?.Data));
        setMonthly_Data(data?.Data);
        let total_amt =0;
        for(let i=0; i<data?.Data?.length; i++){
          total_amt+=data?.Data[i]?.total_amount
        }
        setTotal_Amt(total_amt)
      } else {
        setMonthly_Data([]);
        dispatch(monthly_report_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setMonthly_Data([]);
      dispatch(monthly_report_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//    invoice_creation_monthly_data

export const monthly_data_report_req = () => ({
  type: GET_MONTHLY_DATA_REQUEST,
});
export const monthly_data_report_succ = (data) => ({
  type: GET_MONTHLY_DATA_SUCCESS,
  payload: data,
});
export const monthly_data_report_err = () => ({
  type: GET_MONTHLY_DATA_ERROR,
});

export const get_invoice_monthly_data =
  (
    setInvoice_Monthly_Data,send_month,send_year,selected_salesperson,client,setInvoice_Load,setTotal_Amt
  ) =>
  async (dispatch) => {

    try {
      setInvoice_Load(true)
      dispatch(monthly_data_report_req());
      let res = await fetch(`${base_url}/invoice_creation_monthly_data`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          month_input:send_month,
          year_input:send_year,
          salesperson_input:selected_salesperson,
          client_name:client
        }),
      });
      let data = await res.json();
      setInvoice_Load(false)
      if (data.response.code == 200) {
        dispatch(monthly_data_report_succ(data?.Data));
        setInvoice_Monthly_Data(data?.Data);
        let total_amt =0;
        for(let i=0; i<data?.Data?.length; i++){
          total_amt+=data?.Data[i]?.invoive_total
        }
        setTotal_Amt(total_amt)
      } else {
        setInvoice_Monthly_Data([]);
        dispatch(monthly_data_report_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setInvoice_Load(false)
      setInvoice_Monthly_Data([]);
      dispatch(monthly_data_report_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET USER LIST

export const user_list_req = () => ({
  type: GET_USER_LIST_REQUEST,
});
export const user_list_succ = (data) => ({
  type: GET_USER_LIST_SUCCESS,
  payload: data,
});
export const user_list_err = () => ({
  type: GET_USER_LIST_ERROR,
});

export const get_user_list = (setUser_Data, department) => async (dispatch) => {
  try {
    dispatch(user_list_req());
    let res = await fetch(`${base_url}/get_unique_salesperson`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        dept_id: department,
      }),
    });
    let data = await res.json();

    if (data.response.code == 200) {
      dispatch(user_list_succ(data?.Data));
      setUser_Data(data?.Data);
    } else {
      setUser_Data([]);
      dispatch(user_list_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: data.response.message,
      });
    }
  } catch (error) {
    setUser_Data([]);
    dispatch(user_list_err());
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "error",
      title: "Something Went Wrong",
    });
  }
};

//  GET client LIST

export const client_list_req = () => ({
  type: GET_CLIENT_LIST_REQUEST,
});
export const client_list_succ = (data) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload: data,
});
export const client_list_err = () => ({
  type: GET_CLIENT_LIST_ERROR,
});

export const get_client_list =
  (setClient_Data, department) => async (dispatch) => {
    try {
      dispatch(client_list_req());
      let res = await fetch(`${base_url}/get_unique_client`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id: department,
        }),
      });
      let data = await res.json();

      if (data.response.code == 200) {
        dispatch(client_list_succ(data?.Data));
        setClient_Data(data?.Data);
      } else {
        setClient_Data([]);
        dispatch(client_list_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setClient_Data([]);
      dispatch(client_list_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET invoice_s6 list

export const invoice_s6_req = () => ({
  type: GET_INVOICE_S6_REQUEST,
});
export const invoice_s6_succ = (data) => ({
  type: GET_INVOICE_S6_SUCCESS,
  payload: data,
});
export const invoice_s6_err = () => ({
  type: GET_INVOICE_S6_ERROR,
});

export const get_invoice_s6 =
  (setInvoice_Data, department) => async (dispatch) => {
    try {
      dispatch(invoice_s6_req());
      let res = await fetch(`${base_url}/invoice_creation_s6_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id: department == "Corporate Sales" ? "2" : "1",
        }),
      });
      let data = await res.json();

      if (data.response.code == 200) {
        dispatch(invoice_s6_succ(data?.Data));
        setInvoice_Data(data?.Data);
      } else {
        setInvoice_Data([]);
        dispatch(invoice_s6_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setInvoice_Data([]);
      dispatch(invoice_s6_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// Mail Send

const send_graph_mail = async (fnb_data, corporate_data) => {
  try {
    let res = await fetch(
      "https://beta-hire.equinoxlab.com/api/Candidate_Dashboard.svc/send_mail",
      {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          To: "pratikpaul.equinox@gmail.com",
          Subject: "TEST Graphs 1",
          MailBody: `<img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Corporate Sales',data:[${fnb_data[1]},${fnb_data[2]},${fnb_data[3]},${fnb_data[4]},${fnb_data[5]},${fnb_data[6]},${fnb_data[7]},${fnb_data[8]},${fnb_data[9]},${fnb_data[10]},${fnb_data[11]},0],fill:false},{label:'Fnb Sales',data:[${corporate_data[1]},${corporate_data[2]},${corporate_data[3]},${corporate_data[4]},${corporate_data[5]},${corporate_data[6]},${corporate_data[7]},${corporate_data[8]},${corporate_data[9]},${corporate_data[10]},${corporate_data[11]},0],fill:false}]}}" />`,
          //  `<img src='https://quickchart.io/chart?c={type:'bar',data:{labels:['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_data}],{label:'Corporate Sales',data:[${corporate_data}]}]}} />`
        }),
      }
    );
    let data = await res.json();
  } catch (error) {}
};

//  GET monthly sales analysis

export const monthly_sales_req = () => ({
  type: GET_MONTHLY_SALES_REQUEST,
});
export const monthly_sales_succ = (data) => ({
  type: GET_MONTHLY_SALES_SUCCESS,
  payload: data,
});
export const monthly_sales_err = () => ({
  type: GET_MONTHLY_SALES_ERROR,
});

export const get_monthly_sales =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_sales_req());
      setLoadMonthly(true);
      let res = await fetch(`${base_url}/get_sales_analytics`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          // dept_id: department == "Corporate Sales"?"2":"1"
          get_month: send_month == "ALL" ? "ALL" : Number(send_month),
          get_year: send_year,
        }),
      });
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(monthly_sales_succ(data?.Data));
        setMonthly_Data(data?.Data);
        let flag = false;
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            // setFnb_Sales_Data(fnb_dept)
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
          if (i == data?.Data?.length - 1) {
            flag = true;
          }
        }
        let current_date_time = moment(new Date())
          .format("MM-DD-YYYY hh:mm")
          .split(" ");
        let current_date = current_date_time[0].split("-");
        let current_time = current_date_time[1];

        if (flag == true) {
          // send_graph_mail(fnb_data,corporate_data)
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_sales_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_sales_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET quaterly sales

export const quaterly_sales_req = () => ({
  type: GET_QUATERLY_SALES_REQUEST,
});
export const quaterly_sales_succ = (data) => ({
  type: GET_QUATERLY_SALES_SUCCESS,
  payload: data,
});
export const quaterly_sales_err = () => ({
  type: GET_QUATERLY_SALES_ERROR,
});

export const get_quaterly_sales =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_sales_req());
      setLoad_Data(true);
      let res = await fetch(`${base_url}/get_sales_analytics_quartely`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          get_year: quater_year,
        }),
      });
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(quaterly_sales_succ(data?.Data));
        let fnb_data = [];
        let corporate_data = [];
        // dispatch(yearly_sales_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            // fnb_data.push(data?.Data[i]?.Y3);

            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_sales_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(quaterly_sales_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales data

export const yearly_sales_req = () => ({
  type: GET_YEARLY_SALES_REQUEST,
});
export const yearly_sales_succ = (data) => ({
  type: GET_YEARLY_SALES_SUCCESS,
  payload: data,
});
export const yearly_sales_err = () => ({
  type: GET_YEARLY_SALES_ERROR,
});

export const get_yearly_sales =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_sales_req());
      setLoad_Data(true);
      let res = await fetch(`${base_url}/get_sales_analytics_yearly`);
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0].Years);
        dispatch(yearly_sales_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_sales_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_sales_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales testing

export const yearly_testing_req = () => ({
  type: GET_YEARLY_TESTING_REQUEST,
});
export const yearly_testing_succ = (data) => ({
  type: GET_YEARLY_TESTING_SUCCESS,
  payload: data,
});
export const yearly_testing_err = () => ({
  type: GET_YEARLY_TESTING_ERROR,
});

export const get_yearly_testing =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_testing_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_testing_yearly`
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0]?.Years);
        dispatch(yearly_testing_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_testing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET quaterly sales testing

export const quaterly_testing_req = () => ({
  type: GET_QUATERLY_TESTING_REQUEST,
});
export const quaterly_testing_succ = (data) => ({
  type: GET_QUATERLY_TESTING_SUCCESS,
  payload: data,
});
export const quaterly_testing_err = () => ({
  type: GET_QUATERLY_TESTING_ERROR,
});

export const get_quaterly_testing =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_testing_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_testing_quartely`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            get_year: quater_year,
          }),
        }
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        dispatch(quaterly_testing_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(quaterly_testing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Testing

export const monthly_testing_req = () => ({
  type: GET_MONTHLY_TESTING_REQUEST,
});
export const monthly_testing_succ = (data) => ({
  type: GET_MONTHLY_TESTING_SUCCESS,
  payload: data,
});
export const monthly_testing_err = () => ({
  type: GET_MONTHLY_TESTING_ERROR,
});

export const get_monthly_testing =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_testing_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_testing_monthly`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_month: send_month == "ALL" ? "ALL" : send_month,
            get_year: send_year,
          }),
        }
      );
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }

        dispatch(monthly_testing_succ(data?.Data));
        setMonthly_Data(data?.Data);

        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_testing_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales non_testing

export const yearly_non_testing_req = () => ({
  type: GET_YEARLY_NON_TESTING_REQUEST,
});
export const yearly_non_testing_succ = (data) => ({
  type: GET_YEARLY_NON_TESTING_SUCCESS,
  payload: data,
});
export const yearly_non_testing_err = () => ({
  type: GET_YEARLY_NON_TESTING_ERROR,
});

export const get_yearly_non_testing =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_non_testing_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_non_testing_yearly`
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0]?.Years);
        dispatch(yearly_non_testing_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_non_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_non_testing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET quaterly sales testing

export const quaterly_non_testing_req = () => ({
  type: GET_QUATERLY_NON_TESTING_REQUEST,
});
export const quaterly_non_testing_succ = (data) => ({
  type: GET_QUATERLY_NON_TESTING_SUCCESS,
  payload: data,
});
export const quaterly_non_testing_err = () => ({
  type: GET_QUATERLY_NON_TESTING_ERROR,
});

export const get_quaterly_non_testing =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_non_testing_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_non_testing_quartely`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            get_year: quater_year,
          }),
        }
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        let dept_arr = [];
        dispatch(quaterly_non_testing_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
          dept_arr.push(data?.Data[i]?.Department);
        }
        if (dept_arr[0] == undefined) {
          setFnb_Sales_Data([]);
        }
        if (dept_arr[1] == undefined) {
          setCorporate_Sales_Data([]);
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_non_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(quaterly_non_testing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Non Testing

export const monthly_non_testing_req = () => ({
  type: GET_MONTHLY_NON_TESTING_REQUEST,
});
export const monthly_non_testing_succ = (data) => ({
  type: GET_MONTHLY_NON_TESTING_SUCCESS,
  payload: data,
});
export const monthly_non_testing_err = () => ({
  type: GET_MONTHLY_NON_TESTING_ERROR,
});

export const get_monthly_non_testing =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_non_testing_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_for_non_testing_monthly`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_month: send_month == "ALL" ? "ALL" : Number(send_month),
            get_year: send_year,
          }),
        }
      );
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(monthly_non_testing_succ(data?.Data));
        setMonthly_Data(data?.Data);

        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            // setFnb_Sales_Data(fnb_dept)
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_non_testing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_non_testing_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Pie chart

export const monthly_pie_chart_req = () => ({
  type: GET_MONTHLY_PIECHART_REQUEST,
});
export const monthly_pie_chart_succ = (data) => ({
  type: GET_MONTHLY_PIECHART_SUCCESS,
  payload: data,
});
export const monthly_pie_chart_err = () => ({
  type: GET_MONTHLY_PIECHART_ERROR,
});

export const get_monthly_pie_chart =
  (setLoadMonthly, send_month, send_year, setPieData, pie_dept) =>
  async (dispatch) => {
    try {
      dispatch(monthly_pie_chart_req());
      setLoadMonthly(true);
      let res = await fetch(`${base_url}/get_sales_analytics_piechart`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          // dept_id: department == "Corporate Sales"?"2":"1"
          get_month: send_month,
          get_year: send_year,
          dept_id:
            pie_dept == "Tech" ||
            pie_dept == "Admin" ||
            pie_dept == "Management" ||
            pie_dept == "ALL"
              ? "ALL"
              : pie_dept == "FnB Sales"
              ? "1"
              : pie_dept == "Corporate Sales"
              ? "2"
              : pie_dept,
        }),
      });
      let data = await res.json();
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(monthly_pie_chart_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(monthly_pie_chart_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setLoadMonthly(false);
      dispatch(monthly_pie_chart_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Pie chart

export const quaterly_pie_chart_req = () => ({
  type: GET_QUATERLY_PIECHART_REQUEST,
});
export const quaterly_pie_chart_succ = (data) => ({
  type: GET_QUATERLY_PIECHART_SUCCESS,
  payload: data,
});
export const quaterly_pie_chart_err = () => ({
  type: GET_QUATERLY_PIECHART_ERROR,
});

export const get_quaterly_pie_chart =
  (setPie_Load, setPieData, quater_year, quarter, pie_dept) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_pie_chart_req());
      setPie_Load(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_quartely_piechart`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_quarter: quarter,
            get_year: quater_year == "2024-2025" ? "ALL" : quater_year,
            dept_id:
              pie_dept == "Tech" ||
              pie_dept == "Admin" ||
              pie_dept == "Management" ||
              pie_dept == "ALL"
                ? "ALL"
                : pie_dept == "FnB Sales"
                ? "1"
                : pie_dept == "Corporate Sales"
                ? "2"
                : pie_dept,
          }),
        }
      );
      let data = await res.json();
      setPie_Load(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(quaterly_pie_chart_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(quaterly_pie_chart_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setPie_Load(false);
      dispatch(quaterly_pie_chart_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales Pie chart

export const yearly_pie_chart_req = () => ({
  type: GET_YEARLY_PIECHART_REQUEST,
});
export const yearly_pie_chart_succ = (data) => ({
  type: GET_YEARLY_PIECHART_SUCCESS,
  payload: data,
});
export const yearly_pie_chart_err = () => ({
  type: GET_YEARLY_PIECHART_ERROR,
});

export const get_yearly_pie_chart =
  (setLoadMonthly, setPieData, quater_year, pie_dept) => async (dispatch) => {
    try {
      dispatch(yearly_pie_chart_req());
      setLoadMonthly(true);
      let res = await fetch(`${base_url}/get_sales_analytics_yearly_piechart`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          // dept_id: department == "Corporate Sales"?"2":"1"
          get_year: quater_year == "2024-2025" ? "ALL" : quater_year,
          dept_id:
            pie_dept == "Tech" ||
            pie_dept == "Admin" ||
            pie_dept == "Management" ||
            pie_dept == "ALL"
              ? "ALL"
              : pie_dept == "FnB Sales"
              ? "1"
              : pie_dept == "Corporate Sales"
              ? "2"
              : pie_dept,
        }),
      });
      let data = await res.json();
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(yearly_pie_chart_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(yearly_pie_chart_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setLoadMonthly(false);
      dispatch(yearly_pie_chart_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Testing Location

export const testing_location_req = () => ({
  type: GET_TESTING_LOCATION_REQUEST,
});
export const testing_location_succ = (data) => ({
  type: GET_TESTING_LOCATION_SUCCESS,
  payload: data,
});
export const testing_location_err = () => ({
  type: GET_TESTING_LOCATION_ERROR,
});

export const get_testing_location_data =
  (setLoad, setTesting_Data) => async (dispatch) => {
    try {
      dispatch(testing_location_req());
      setLoad(true);
      let res = await fetch(`${base_url}/s6_for_testing_location`);
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setTesting_Data([]);
        }
        dispatch(testing_location_succ(data?.Data));
        // let temp_arr =[]
        // for(let i=0; i<=data?.Data?.length; i++){
        //   temp_arr.push(data?.Data[i])
        //   if(data?.Data[i]?.Testing_Location == "Testing Location Not Updated"){
        //     temp_arr.pop();
        //     break;
        //   }
        // }
        setTesting_Data(data?.Data);
      } else {
        setTesting_Data([]);
        dispatch(testing_location_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setTesting_Data([]);
      setLoad(false);
      dispatch(testing_location_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Testing Location Graph

export const testing_location_graph_req = () => ({
  type: GET_TESTING_LOCATION_GRAPH_REQUEST,
});
export const testing_location_graph_succ = (data) => ({
  type: GET_TESTING_LOCATION_GRAPH_SUCCESS,
  payload: data,
});
export const testing_location_graph_err = () => ({
  type: GET_TESTING_LOCATION_GRAPH_ERROR,
});

export const get_testing_location_graph_data =
  (
    setLoad,
    setTesting_Graph_Data_Mum,
    setTesting_Graph_Data_Ban,
    setTesting_Graph_Data_Hyd,
    setTesting_Graph_Data_Chen,
    setTesting_Graph_Data_Kol,
    setTesting_Graph_Data_Noid,
    testing_location
  ) =>
  async (dispatch) => {
    try {
      dispatch(testing_location_graph_req());
      setLoad(true);
      let res = await fetch(`${base_url}/get_s6_for_testing_location`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          location: "ALL",
        }),
      });
      let data = await res.json();
      setLoad(false);

      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setTesting_Graph_Data_Ban([]);
          setTesting_Graph_Data_Chen([]);
          setTesting_Graph_Data_Hyd([]);
          setTesting_Graph_Data_Kol([]);
          setTesting_Graph_Data_Mum([]);
          setTesting_Graph_Data_Noid([]);
        }
        dispatch(testing_location_graph_succ(data?.Data));
        setTesting_Graph_Data_Ban(data?.Data[0]?.Bangalore);
        setTesting_Graph_Data_Chen(data?.Data[1]?.Chennai);
        setTesting_Graph_Data_Hyd(data?.Data[2]?.Hyderabad);
        setTesting_Graph_Data_Kol(data?.Data[3]?.Kolkata);
        setTesting_Graph_Data_Mum(data?.Data[4]?.Mumbai);
        setTesting_Graph_Data_Noid(data?.Data[5]?.Noida);
      } else {
        setTesting_Graph_Data_Ban([]);
        setTesting_Graph_Data_Chen([]);
        setTesting_Graph_Data_Hyd([]);
        setTesting_Graph_Data_Kol([]);
        setTesting_Graph_Data_Mum([]);
        setTesting_Graph_Data_Noid([]);
        dispatch(testing_location_graph_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setTesting_Graph_Data_Ban([]);
      setTesting_Graph_Data_Chen([]);
      setTesting_Graph_Data_Hyd([]);
      setTesting_Graph_Data_Kol([]);
      setTesting_Graph_Data_Mum([]);
      setTesting_Graph_Data_Noid([]);
      setLoad(false);
      dispatch(testing_location_graph_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Client Type Revenue

export const client_type_revenue_req = () => ({
  type: GET_CLIENT_TYPE_REVENUE_REQUEST,
});
export const client_type_revenue_succ = (data) => ({
  type: GET_CLIENT_TYPE_REVENUE_SUCCESS,
  payload: data,
});
export const client_type_revenue_err = () => ({
  type: GET_CLIENT_TYPE_REVENUE_ERROR,
});

export const get_client_type_revenue_data =
  (setClient_Load, setClient_Data, department) => async (dispatch) => {
    try {
      dispatch(client_type_revenue_req());
      setClient_Load(true);
      let res = await fetch(`${base_url}/client_type_s6_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_name:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department,
        }),
      });

      let data = await res.json();
      setClient_Load(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setClient_Data([]);
        }
        dispatch(client_type_revenue_succ(data?.Data));
        setClient_Data(data?.Data);
      } else {
        setClient_Data([]);
        dispatch(client_type_revenue_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setClient_Data([]);
      setClient_Load(false);
      dispatch(client_type_revenue_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Client Type Sample

export const client_type_sample_req = () => ({
  type: GET_CLIENT_TYPE_SAMPLE_REQUEST,
});
export const client_type_sample_succ = (data) => ({
  type: GET_CLIENT_TYPE_SAMPLE_SUCCESS,
  payload: data,
});
export const client_type_sample_err = () => ({
  type: GET_CLIENT_TYPE_SAMPLE_ERROR,
});

export const get_client_type_sample_data =
  (
    setLoad,
    department,
    params_count,
    start_date,
    end_date,
    setSample_Count_Data
  ) =>
  async (dispatch) => {
    try {
      dispatch(client_type_sample_req());
      setLoad(true);
      let res = await fetch(`${base_url}/client_type_sample_type_quantity`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department == "FnB Sales"
              ? "1"
              : department == "Corporate Sales"
              ? "2"
              : "",
          get_date: params_count == "Custom Date" ? "" : params_count,
          start_date: params_count == "Custom Date" ? start_date : "",
          end_date: params_count == "Custom Date" ? end_date : "",
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setSample_Count_Data([]);
        }
        dispatch(client_type_sample_succ(data?.Data));
        setSample_Count_Data(data?.Data);
      } else {
        setSample_Count_Data([]);
        dispatch(client_type_sample_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setSample_Count_Data([]);
      setLoad(false);
      dispatch(client_type_sample_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Client Type Sample Revenue

export const client_type_sample_revenue_req = () => ({
  type: GET_CLIENT_TYPE_SAMPLE_REVENUE_REQUEST,
});
export const client_type_sample_revenue_succ = (data) => ({
  type: GET_CLIENT_TYPE_SAMPLE_REVENUE_SUCCESS,
  payload: data,
});
export const client_type_sample_revenue_err = () => ({
  type: GET_CLIENT_TYPE_SAMPLE_REVENUE_ERROR,
});

export const get_client_type_sample_revenue_data =
  (
    setLoad,
    department,
    params_revenue,
    start_date,
    end_date,
    setSample_Revenue_Data
  ) =>
  async (dispatch) => {
    try {
      dispatch(client_type_sample_revenue_req());
      setLoad(true);
      let res = await fetch(`${base_url}/client_type_sample_type_amount`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department == "FnB Sales"
              ? "1"
              : department == "Corporate Sales"
              ? "2"
              : "",
          get_date: params_revenue == "Custom Date" ? "" : params_revenue,
          start_date: params_revenue == "Custom Date" ? start_date : "",
          end_date: params_revenue == "Custom Date" ? end_date : "",
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setSample_Revenue_Data([]);
        }
        dispatch(client_type_sample_revenue_succ(data?.Data));
        setSample_Revenue_Data(data?.Data);
      } else {
        setSample_Revenue_Data([]);
        dispatch(client_type_sample_revenue_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setSample_Revenue_Data([]);
      setLoad(false);
      dispatch(client_type_sample_revenue_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Client Type Sample Revenue

export const service_penetration_req = () => ({
  type: GET_SERVICE_PENETRATION_REQUEST,
});
export const service_penetration_succ = (data) => ({
  type: GET_SERVICE_PENETRATION_SUCCESS,
  payload: data,
});
export const service_penetration_err = () => ({
  type: GET_SERVICE_PENETRATION_ERROR,
});

export const get_service_penetration_data =
  (
    setService_Data,
    setService_Load,
    params,
    start_date,
    end_date,
    department
  ) =>
  async (dispatch) => {
    try {
      dispatch(service_penetration_req());
      setService_Load(true);
      let res = await fetch(`${base_url}/service_penetration_dashboard`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          get_date: params == "Custom Date" ? "" : params,
          start_date: params == "Custom Date" ? start_date : "",
          end_date: params == "Custom Date" ? end_date : "",
          dept_id:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department == "FnB Sales"
              ? "1"
              : department == "Corporate Sales"
              ? "2"
              : "",
        }),
      });
      let data = await res.json();
      setService_Load(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setService_Data([]);
        }
        dispatch(service_penetration_succ(data?.Data));
        setService_Data(data?.Data);
      } else {
        setService_Data([]);
        dispatch(service_penetration_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setService_Data([]);
      setService_Load(false);
      dispatch(service_penetration_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// //  GET BDE Calls Done

// export const bde_calls_req = () => ({
//   type: GET_BDE_CALLS_REQUEST,
// });
// export const bde_calls_succ = (data) => ({
//   type: GET_BDE_CALLS_SUCCESS,
//   payload: data,
// });
// export const bde_calls_err = () => ({
//   type: GET_BDE_CALLS_ERROR,
// });

// export const get_bde_calls_data =
//   (
//     setCalls_Data,setCalls_Load,department
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch(bde_calls_req());
//       setCalls_Load(true);
//       let res = await fetch(`${base_url}/s6_calls_done`, {
//         method: "POST",
//         headers: { "Content-Type": "Application/Json" },
//         body: JSON.stringify({
//           DEPT_NAME:(department == "Tech" ||
//           department == "Admin" ||
//           department == "Management")
//             ? "ALL":department
//         }),
//       });
//       let data = await res.json();
//       setCalls_Load(false);
//       if (data.response.code == 200) {
//         if (data?.response?.message == "Data Not Found") {
//           setCalls_Data([]);
//         }
//         dispatch(bde_calls_succ(data?.Data));
//         setCalls_Data(data?.Data);
//       } else {
//         setCalls_Data([]);
//         dispatch(bde_calls_err());
//         const Toast = Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 1500,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         });
//         Toast.fire({
//           icon: "error",
//           title: data.response.message,
//         });
//       }
//     } catch (error) {
//       setCalls_Data([]);
//       setCalls_Load(false);
//       dispatch(bde_calls_err());
//       const Toast = Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 1500,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       });
//       Toast.fire({
//         icon: "error",
//         title: "Something Went Wrong",
//       });
//     }
//   };

// //  GET BDE Leads Added

// export const bde_meeting_req = () => ({
//   type: GET_BDE_MEETINGS_REQUEST,
// });
// export const bde_meeting_succ = (data) => ({
//   type: GET_BDE_MEETINGS_SUCCESS,
//   payload: data,
// });
// export const bde_meeting_err = () => ({
//   type: GET_BDE_MEETINGS_ERROR,
// });

// export const get_bde_meeting_data =
//   (
//     setMeeting_Data,setMeeting_Load,department
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch(bde_meeting_req());
//       setMeeting_Load(true);
//       let res = await fetch(`${base_url}/s6_meeting_done`, {
//         method: "POST",
//         headers: { "Content-Type": "Application/Json" },
//         body: JSON.stringify({
//           DEPT_NAME:(department == "Tech" ||
//           department == "Admin" ||
//           department == "Management")
//             ? "ALL":department
//         }),
//       });
//       let data = await res.json();
//       setMeeting_Load(false);
//       if (data.response.code == 200) {
//         if (data?.response?.message == "Data Not Found") {
//           setMeeting_Data([]);
//         }
//         dispatch(bde_meeting_succ(data?.Data));
//         setMeeting_Data(data?.Data);
//       } else {
//         setMeeting_Data([]);
//         dispatch(bde_meeting_err());
//         const Toast = Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 1500,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         });
//         Toast.fire({
//           icon: "error",
//           title: data.response.message,
//         });
//       }
//     } catch (error) {
//       setMeeting_Data([]);
//       setMeeting_Load(false);
//       dispatch(bde_meeting_err());
//       const Toast = Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 1500,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       });
//       Toast.fire({
//         icon: "error",
//         title: "Something Went Wrong",
//       });
//     }
//   };

// //  GET BDE Leads Added

// export const bde_leads_req = () => ({
//   type: GET_BDE_LEADS_REQUEST,
// });
// export const bde_leads_succ = (data) => ({
//   type: GET_BDE_LEADS_SUCCESS,
//   payload: data,
// });
// export const bde_leads_err = () => ({
//   type: GET_BDE_LEADS_ERROR,
// });

// export const get_bde_leads_data =
//   (
//     setLeads_Data,setLeads_Load,department
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch(bde_leads_req());
//       setLeads_Load(true);
//       let res = await fetch(`${base_url}/s6_leads_added`, {
//         method: "POST",
//         headers: { "Content-Type": "Application/Json" },
//         body: JSON.stringify({
//           DEPT_NAME:(department == "Tech" ||
//           department == "Admin" ||
//           department == "Management")
//             ? "ALL":department
//         }),
//       });
//       let data = await res.json();
//       setLeads_Load(false);
//       if (data.response.code == 200) {
//         if (data?.response?.message == "Data Not Found") {
//           setLeads_Data([]);
//         }
//         dispatch(bde_leads_succ(data?.Data));
//         setLeads_Data(data?.Data);
//       } else {
//         setLeads_Data([]);
//         dispatch(bde_leads_err());
//         const Toast = Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 1500,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         });
//         Toast.fire({
//           icon: "error",
//           title: data.response.message,
//         });
//       }
//     } catch (error) {
//       setLeads_Data([]);
//       setLeads_Load(false);
//       dispatch(bde_leads_err());
//       const Toast = Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 1500,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       });
//       Toast.fire({
//         icon: "error",
//         title: "Something Went Wrong",
//       });
//     }
//   };

//  GET BDE Dashboards

export const check_leads_req = () => ({
  type: GET_CHECK_BDE_REQUEST,
});
export const check_leads_succ = (data) => ({
  type: GET_CHECK_BDE_SUCCESS,
  payload: data,
});
export const check_leads_err = () => ({
  type: GET_CHECK_BDE_ERROR,
});

export const get_check_leads_data =
  (
    setCheck_Data,
    setCheck_Load,
    department,
    reporting_person,
    alignment,
    signal
  ) =>
  async (dispatch) => {
    try {
      dispatch(check_leads_req());
      setCheck_Load(true);
      let url = `${base_url}/s6_calls_done`;
      if (alignment == "s6_meeting_done") {
        url = `${base_url}/s6_meeting_done`;
      }
      if (alignment == "s6_leads_added") {
        url = `${base_url}/s6_leads_added`;
      }
      if (alignment == "s6_proposal_sent") {
        url = `${base_url}/s6_proposal_sent`;
      }
      if (alignment == "invoice_creation_s6_report") {
        url = `${base_url}/invoice_creation_s6_report`;
      }
      if (alignment == "order_amount_s6_report") {
        url = `${base_url}/order_amount_s6_report`;
      }
      if (alignment == "s6_interested") {
        url = `${base_url}/s6_interested`;
      }
      if (alignment == "s6_leads_converted") {
        url = `${base_url}/s6_leads_converted`;
      }
      if (alignment == "s6_customer_by_salesperson") {
        url = `${base_url}/s6_customer_by_salesperson`;
      }

      let res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        signal: signal,
        body: JSON.stringify({
          DEPT_NAME:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department,
          REPORTING_PERSON: reporting_person == "All" ? null : reporting_person,
        }),
      });
      let data = await res.json();
      setCheck_Load(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setCheck_Data([]);
        }
        dispatch(check_leads_succ(data?.Data));

        if (alignment == "order_amount_s6_report") {
          const storage = window.sessionStorage;
          let user_id = storage.getItem("USER_GUID");
          let data_arr = [];
          data?.Data?.forEach((ele) => {
            // user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a"
            if (ele.NAME == "Ashwin Bhadri") {
              if (user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a") {
                data_arr.push(ele);
              } else {
                let x = 1;
              }
            } else {
              data_arr.push(ele);
            }
          });
          setCheck_Data(data_arr);
        } else if (alignment == "invoice_creation_s6_report") {
          const storage = window.sessionStorage;
          let user_id = storage.getItem("USER_GUID");
          let data_arr = [];
          data?.Data?.forEach((ele) => {
            // user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a"
            if (ele.NAME == "Ashwin Bhadri") {
              if (user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a") {
                data_arr.push(ele);
              } else {
                let x = 1;
              }
            } else {
              data_arr.push(ele);
            }
          });
          setCheck_Data(data_arr);
        } else if (alignment == "s6_customer_by_salesperson") {
          const storage = window.sessionStorage;
          let user_id = storage.getItem("USER_GUID");
          let data_arr = [];
          data?.Data?.forEach((ele) => {
            // user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a"
            if (ele.NAME == "Ashwin Bhadri") {
              if (user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a") {
                data_arr.push(ele);
              } else {
                let x = 1;
              }
            } else {
              data_arr.push(ele);
            }
          });
          setCheck_Data(data_arr);
        } else {
          setCheck_Data(data?.Data);
        }
      } else {
        setCheck_Data([]);
        dispatch(check_leads_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCheck_Data([]);
      // setCheck_Load(false);
      dispatch(check_leads_err());
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Something Went Wrong",
      // });
    }
  };

// BDE Reporting Persons

export const bde_reporting_person_req = () => ({
  type: GET_REPORTING_PERSON_REQUEST,
});
export const bde_reporting_person_succ = (data) => ({
  type: GET_REPORTING_PERSON_SUCCESS,
  payload: data,
});
export const bde_reporting_person_err = () => ({
  type: GET_REPORTING_PERSON_ERROR,
});

export const bde_reporting_person_list =
  (department, setReporting_Data) => async (dispatch) => {
    try {
      dispatch(bde_reporting_person_req());
      let res = await fetch(`${base_url}/get_reporting_person`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          DEPT_NAME:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department,
        }),
      });
      let data = await res.json();

      if (data?.response?.code == 200) {
        dispatch(bde_reporting_person_succ(data?.Data));
        setReporting_Data(data?.Data);
      } else {
        setReporting_Data([]);
        dispatch(bde_reporting_person_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setReporting_Data([]);
      dispatch(bde_reporting_person_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// BDE Reporting Persons Hub

export const bde_reporting_person_hub_req = () => ({
  type: GET_REPORTING_PERSON_REQUEST,
});
export const bde_reporting_person_hub_succ = (data) => ({
  type: GET_REPORTING_PERSON_SUCCESS,
  payload: data,
});
export const bde_reporting_person_hub_err = () => ({
  type: GET_REPORTING_PERSON_ERROR,
});

export const bde_reporting_person_hub_list =
  (department, setReporting_Data_Hub) => async (dispatch) => {
    try {
      dispatch(bde_reporting_person_hub_req());
      let res = await fetch(`${base_url}/get_reporting_person_hub`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          DEPT_NAME:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department,
        }),
      });
      let data = await res.json();

      if (data?.response?.code == 200) {
        dispatch(bde_reporting_person_hub_succ(data?.Data));
        setReporting_Data_Hub(data?.Data);
      } else {
        setReporting_Data_Hub([]);
        dispatch(bde_reporting_person_hub_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setReporting_Data_Hub([]);
      dispatch(bde_reporting_person_hub_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// Nps Yearly percentage data

export const nps_percentage_yearly_req = () => ({
  type: GET_REPORTING_PERSON_REQUEST,
});
export const nps_percentage_yearly_succ = (data) => ({
  type: GET_REPORTING_PERSON_SUCCESS,
  payload: data,
});
export const nps_percentage_yearly_err = () => ({
  type: GET_REPORTING_PERSON_ERROR,
});

export const nps_percentage_yearly_data =
  (nps_year, setNps_Yearly_Data, setNps_Load) => async (dispatch) => {
    try {
      setNps_Load(true);
      dispatch(nps_percentage_yearly_req());
      let res = await fetch(`${base_url}/rating_report_nps_percentage_yearly`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          get_year: nps_year,
        }),
      });
      let data = await res.json();
      setNps_Load(false);
      if (data?.response?.code == 200) {
        let nps_arr = [];
        dispatch(nps_percentage_yearly_succ(data?.Data));
        nps_arr.push(null);
        nps_arr.push(data?.Data[0]?.April);
        nps_arr.push(data?.Data[0]?.May);
        nps_arr.push(data?.Data[0]?.June);
        nps_arr.push(data?.Data[0]?.July);
        nps_arr.push(data?.Data[0]?.August);
        nps_arr.push(data?.Data[0]?.Septmember);
        nps_arr.push(data?.Data[0]?.October);
        nps_arr.push(data?.Data[0]?.November);
        nps_arr.push(data?.Data[0]?.December);
        nps_arr.push(data?.Data[0]?.January);
        nps_arr.push(data?.Data[0]?.February);
        nps_arr.push(data?.Data[0]?.March);
        setNps_Yearly_Data(nps_arr);
      } else {
        setNps_Yearly_Data([]);
        dispatch(nps_percentage_yearly_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setNps_Load(false);
      setNps_Yearly_Data([]);
      dispatch(nps_percentage_yearly_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// For Quantity (count)

// get monthly sales data count

export const monthly_sales_count_req = () => ({
  type: GET_MONTHLY_SALES_COUNT_REQUEST,
});
export const monthly_sales_count_succ = (data) => ({
  type: GET_MONTHLY_SALES_COUNT_SUCCESS,
  payload: data,
});
export const monthly_sales_count_err = () => ({
  type: GET_MONTHLY_SALES_COUNT_ERROR,
});

export const get_monthly_sales_count =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_sales_count_req());
      setLoadMonthly(true);
      let res = await fetch(`${base_url}/get_sales_analytics_quantity`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          // dept_id: department == "Corporate Sales"?"2":"1"
          get_month: send_month == "ALL" ? "ALL" : Number(send_month),
          get_year: send_year,
        }),
      });
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(monthly_sales_count_succ(data?.Data));
        setMonthly_Data(data?.Data);

        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            // setFnb_Sales_Data(fnb_dept)
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_sales_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoadMonthly(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_sales_count_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Pie chart (count)

export const monthly_pie_chart_count_req = () => ({
  type: GET_MONTHLY_PIECHART_COUNT_REQUEST,
});
export const monthly_pie_chart_count_succ = (data) => ({
  type: GET_MONTHLY_PIECHART_COUNT_SUCCESS,
  payload: data,
});
export const monthly_pie_chart_count_err = () => ({
  type: GET_MONTHLY_PIECHART_COUNT_ERROR,
});

export const get_monthly_pie_chart_count =
  (setLoadMonthly, send_month, send_year, setPieData, pie_dept) =>
  async (dispatch) => {
    try {
      dispatch(monthly_pie_chart_count_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_piechart_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_month: send_month,
            get_year: send_year,
            dept_id:
              pie_dept == "Tech" ||
              pie_dept == "Admin" ||
              pie_dept == "Management" ||
              pie_dept == "ALL"
                ? "ALL"
                : pie_dept == "FnB Sales"
                ? "1"
                : pie_dept == "Corporate Sales"
                ? "2"
                : pie_dept,
          }),
        }
      );
      let data = await res.json();
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(monthly_pie_chart_count_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(monthly_pie_chart_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setLoadMonthly(false);
      dispatch(monthly_pie_chart_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// Quaterly Sales Count

export const quaterly_sales_count_req = () => ({
  type: GET_QUATERLY_SALES_COUNT_REQUEST,
});
export const quaterly_sales_count_succ = (data) => ({
  type: GET_QUATERLY_SALES_COUNT_SUCCESS,
  payload: data,
});
export const quaterly_sales_count_err = () => ({
  type: GET_QUATERLY_SALES_COUNT_ERROR,
});

export const get_quaterly_sales_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_sales_count_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_quartely_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            get_year: quater_year,
          }),
        }
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(quaterly_sales_count_succ(data?.Data));
        let fnb_data = [];
        let corporate_data = [];
        dispatch(yearly_sales_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            // fnb_data.push(data?.Data[i]?.Y3);

            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_sales_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(quaterly_sales_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Quaterly sales Pie chart

export const quaterly_pie_chart_count_req = () => ({
  type: GET_QUATERLY_PIECHART_REQUEST,
});
export const quaterly_pie_chart_count_succ = (data) => ({
  type: GET_QUATERLY_PIECHART_SUCCESS,
  payload: data,
});
export const quaterly_pie_chart_count_err = () => ({
  type: GET_QUATERLY_PIECHART_ERROR,
});

export const get_quaterly_pie_chart_count =
  (setPie_Load, setPieData, quater_year, quarter, pie_dept) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_pie_chart_count_req());
      setPie_Load(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_quartely_piechart_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_quarter: quarter,
            get_year: quater_year == "2024-2025" ? "ALL" : quater_year,
            dept_id:
              pie_dept == "Tech" ||
              pie_dept == "Admin" ||
              pie_dept == "Management" ||
              pie_dept == "ALL"
                ? "ALL"
                : pie_dept == "FnB Sales"
                ? "1"
                : pie_dept == "Corporate Sales"
                ? "2"
                : pie_dept,
          }),
        }
      );
      let data = await res.json();
      setPie_Load(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(quaterly_pie_chart_count_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(quaterly_pie_chart_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setPie_Load(false);
      dispatch(quaterly_pie_chart_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales data (count)

export const yearly_sales_count_req = () => ({
  type: GET_YEARLY_SALES_REQUEST,
});
export const yearly_sales_count_succ = (data) => ({
  type: GET_YEARLY_SALES_SUCCESS,
  payload: data,
});
export const yearly_sales_count_err = () => ({
  type: GET_YEARLY_SALES_ERROR,
});

export const get_yearly_sales_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_sales_count_req());
      setLoad_Data(true);
      let res = await fetch(`${base_url}/get_sales_analytics_yearly_quantity`);
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0].Years);
        dispatch(yearly_sales_count_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_sales_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_sales_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales Pie chart (Count)

export const yearly_pie_chart_count_req = () => ({
  type: GET_YEARLY_PIECHART_COUNT_REQUEST,
});
export const yearly_pie_chart_count_succ = (data) => ({
  type: GET_YEARLY_PIECHART_COUNT_SUCCESS,
  payload: data,
});
export const yearly_pie_chart_count_err = () => ({
  type: GET_YEARLY_PIECHART_COUNT_ERROR,
});

export const get_yearly_pie_chart_count =
  (setLoadMonthly, setPieData, quater_year, pie_dept) => async (dispatch) => {
    try {
      dispatch(yearly_pie_chart_count_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_yearly_piechart_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_year: quater_year == "2024-2025" ? "ALL" : quater_year,
            dept_id:
              pie_dept == "Tech" ||
              pie_dept == "Admin" ||
              pie_dept == "Management" ||
              pie_dept == "ALL"
                ? "ALL"
                : pie_dept == "FnB Sales"
                ? "1"
                : pie_dept == "Corporate Sales"
                ? "2"
                : pie_dept,
          }),
        }
      );
      let data = await res.json();
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setPieData([]);
        }
        dispatch(yearly_pie_chart_count_succ(data?.Data));
        setPieData(data?.Data);
      } else {
        setPieData([]);
        dispatch(yearly_pie_chart_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setPieData([]);
      setLoadMonthly(false);
      dispatch(yearly_pie_chart_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Testing Count

export const monthly_testing_count_req = () => ({
  type: GET_MONTHLY_TESTING_SALES_COUNT_REQUEST,
});
export const monthly_testing_count_succ = (data) => ({
  type: GET_MONTHLY_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const monthly_testing_count_err = () => ({
  type: GET_MONTHLY_TESTING_SALES_COUNT_ERROR,
});

export const get_monthly_testing_count =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_testing_count_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_testing_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_month: send_month == "ALL" ? "ALL" : Number(send_month),
            get_year: send_year,
          }),
        }
      );
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }

        dispatch(monthly_testing_count_succ(data?.Data));
        setMonthly_Data(data?.Data);

        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoadMonthly(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_testing_count_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET quaterly sales testing (Count)

export const quaterly_testing_count_req = () => ({
  type: GET_QUATERLY_TESTING_SALES_COUNT_REQUEST,
});
export const quaterly_testing_count_succ = (data) => ({
  type: GET_QUATERLY_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const quaterly_testing_count_err = () => ({
  type: GET_QUATERLY_TESTING_SALES_COUNT_ERROR,
});

export const get_quaterly_testing_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_testing_count_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_quartely_quantity_testing`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            get_year: quater_year,
          }),
        }
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        dispatch(quaterly_testing_count_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      setLoad_Data(false);
      dispatch(quaterly_testing_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET monthly sales Non Testing (Count)

export const monthly_non_testing_count_req = () => ({
  type: GET_MONTHLY_NON_TESTING_SALES_COUNT_REQUEST,
});
export const monthly_non_testing_count_succ = (data) => ({
  type: GET_MONTHLY_NON_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const monthly_non_testing_count_err = () => ({
  type: GET_MONTHLY_NON_TESTING_SALES_COUNT_ERROR,
});

export const get_monthly_non_testing_count =
  (
    setLoadMonthly,
    setMonthly_Data,
    setFnb_Sales_Data,
    setCorporate_Sales_Data,
    send_month,
    send_year
  ) =>
  async (dispatch) => {
    try {
      dispatch(monthly_non_testing_count_req());
      setLoadMonthly(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_non_testing_quantity`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // dept_id: department == "Corporate Sales"?"2":"1"
            get_month: send_month == "ALL" ? "ALL" : Number(send_month),
            get_year: send_year,
          }),
        }
      );
      let data = await res.json();
      let fnb_dept = [];
      let fnb_data = [];
      let corporate_data = [];
      setLoadMonthly(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        dispatch(monthly_non_testing_count_succ(data?.Data));
        setMonthly_Data(data?.Data);

        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            // setFnb_Sales_Data(fnb_dept)
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.April);
            fnb_data.push(data?.Data[i]?.May);
            fnb_data.push(data?.Data[i]?.June);
            fnb_data.push(data?.Data[i]?.July);
            fnb_data.push(data?.Data[i]?.August);
            fnb_data.push(data?.Data[i]?.Septmember);
            fnb_data.push(data?.Data[i]?.October);
            fnb_data.push(data?.Data[i]?.November);
            fnb_data.push(data?.Data[i]?.December);
            fnb_data.push(data?.Data[i]?.January);
            fnb_data.push(data?.Data[i]?.February);
            fnb_data.push(data?.Data[i]?.March);
            setFnb_Sales_Data(fnb_data);
          }

          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.April);
            corporate_data.push(data?.Data[i]?.May);
            corporate_data.push(data?.Data[i]?.June);
            corporate_data.push(data?.Data[i]?.July);
            corporate_data.push(data?.Data[i]?.August);
            corporate_data.push(data?.Data[i]?.Septmember);
            corporate_data.push(data?.Data[i]?.October);
            corporate_data.push(data?.Data[i]?.November);
            corporate_data.push(data?.Data[i]?.December);
            corporate_data.push(data?.Data[i]?.January);
            corporate_data.push(data?.Data[i]?.February);
            corporate_data.push(data?.Data[i]?.March);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(monthly_non_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoadMonthly(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(monthly_non_testing_count_err());
      setMonthly_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET quaterly sales non testing

export const quaterly_non_testing_count_req = () => ({
  type: GET_QUATERLY_NON_TESTING_SALES_COUNT_REQUEST,
});
export const quaterly_non_testing_count_succ = (data) => ({
  type: GET_QUATERLY_NON_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const quaterly_non_testing_count_err = () => ({
  type: GET_QUATERLY_NON_TESTING_SALES_COUNT_ERROR,
});

export const get_quaterly_non_testing_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, quater_year) =>
  async (dispatch) => {
    try {
      dispatch(quaterly_non_testing_count_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_quartely_quantity_non_testing`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            get_year: quater_year,
          }),
        }
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        let dept_arr = [];
        dispatch(quaterly_non_testing_count_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Q1);
            fnb_data.push(data?.Data[i]?.Q2);
            fnb_data.push(data?.Data[i]?.Q3);
            fnb_data.push(data?.Data[i]?.Q4);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Q1);
            corporate_data.push(data?.Data[i]?.Q2);
            corporate_data.push(data?.Data[i]?.Q3);
            corporate_data.push(data?.Data[i]?.Q4);
            setCorporate_Sales_Data(corporate_data);
          }
          dept_arr.push(data?.Data[i]?.Department);
        }
        if (dept_arr[0] == undefined) {
          setFnb_Sales_Data([]);
        }
        if (dept_arr[1] == undefined) {
          setCorporate_Sales_Data([]);
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(quaterly_non_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoad_Data(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(quaterly_non_testing_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales testing

export const yearly_testing_count_req = () => ({
  type: GET_YEARLY_TESTING_SALES_COUNT_REQUEST,
});
export const yearly_testing_count_succ = (data) => ({
  type: GET_YEARLY_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const yearly_testing_count_err = () => ({
  type: GET_YEARLY_TESTING_SALES_COUNT_ERROR,
});

export const get_yearly_testing_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_testing_count_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_yearly_quantity_testing`
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0]?.Years);
        dispatch(yearly_testing_count_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoad_Data(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_testing_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET yearly sales non_testing

export const yearly_non_testing_count_req = () => ({
  type: GET_YEARLY_NON_TESTING_SALES_COUNT_REQUEST,
});
export const yearly_non_testing_count_succ = (data) => ({
  type: GET_YEARLY_NON_TESTING_SALES_COUNT_SUCCESS,
  payload: data,
});
export const yearly_non_testing_count_err = () => ({
  type: GET_YEARLY_NON_TESTING_SALES_COUNT_ERROR,
});

export const get_yearly_non_testing_count =
  (setLoad_Data, setFnb_Sales_Data, setCorporate_Sales_Data, setYears_Data) =>
  async (dispatch) => {
    try {
      dispatch(yearly_non_testing_count_req());
      setLoad_Data(true);
      let res = await fetch(
        `${base_url}/get_sales_analytics_yearly_quantity_non_testing`
      );
      let data = await res.json();
      setLoad_Data(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setFnb_Sales_Data([]);
          setCorporate_Sales_Data([]);
        }
        let fnb_data = [];
        let corporate_data = [];
        setYears_Data(data?.Data[0]?.Years);
        dispatch(yearly_non_testing_count_succ(data?.Data));
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Department == "Sales - FnB") {
            fnb_data.push(null);
            fnb_data.push(data?.Data[i]?.Y);
            fnb_data.push(data?.Data[i]?.Y1);
            fnb_data.push(data?.Data[i]?.Y2);
            fnb_data.push(data?.Data[i]?.Y3);
            fnb_data.push(data?.Data[i]?.Y4);
            fnb_data.push(data?.Data[i]?.Y5);
            fnb_data.push(data?.Data[i]?.Y6);
            setFnb_Sales_Data(fnb_data);
          }
          // fnb_dept.push(data?.Data[i]?.Month_Year)
          if (data?.Data[i]?.Department == "Sales - Corporate") {
            corporate_data.push(null);
            corporate_data.push(data?.Data[i]?.Y);
            corporate_data.push(data?.Data[i]?.Y1);
            corporate_data.push(data?.Data[i]?.Y2);
            corporate_data.push(data?.Data[i]?.Y3);
            corporate_data.push(data?.Data[i]?.Y4);
            corporate_data.push(data?.Data[i]?.Y5);
            corporate_data.push(data?.Data[i]?.Y6);
            setCorporate_Sales_Data(corporate_data);
          }
        }
      } else {
        setCorporate_Sales_Data([]);
        setFnb_Sales_Data([]);
        dispatch(yearly_non_testing_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setLoad_Data(false);
      setCorporate_Sales_Data([]);
      setFnb_Sales_Data([]);
      dispatch(yearly_non_testing_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

export const send_mail_graph_req = () => ({
  type: SEND_GRAPH_MAIL_REQUEST,
});
export const send_mail_graph_succ = (data) => ({
  type: SEND_GRAPH_MAIL_SUCCESS,
  payload: data,
});
export const send_mail_graph_err = () => ({
  type: SEND_GRAPH_MAIL_ERROR,
});

export const get_send_mail_graph =
  (
    fnb_sales_data_month,
    corporate_sales_data_month,
    pie_data_month,
    fnb_sales_data_month_testing,
    corporate_sales_data_month_testing,
    fnb_sales_data_month_non_test,
    corporate_sales_data_month_non_test
  ) =>
  async (dispatch) => {
    try {
      dispatch(send_mail_graph_req());
      let res = await fetch(
        "https://beta-hire.equinoxlab.com/api/Candidate_Dashboard.svc/send_mail",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            To: "pratikpaul.equinox@gmail.com",
            Subject: "TEST Graphs 1",
            MailBody:
              // `<p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing[1]},${fnb_sales_data_month_testing[2]},${fnb_sales_data_month_testing[3]},${fnb_sales_data_month_testing[4]},${fnb_sales_data_month_testing[5]},${fnb_sales_data_month_testing[6]},${fnb_sales_data_month_testing[7]},${fnb_sales_data_month_testing[8]},${fnb_sales_data_month_testing[9]},${fnb_sales_data_month_testing[10]},${fnb_sales_data_month_testing[11]},0],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing[1]},${corporate_sales_data_month_testing[2]},${corporate_sales_data_month_testing[3]},${corporate_sales_data_month_testing[4]},${corporate_sales_data_month_testing[5]},${corporate_sales_data_month_testing[6]},${corporate_sales_data_month_testing[7]},${corporate_sales_data_month_testing[8]},${corporate_sales_data_month_testing[9]},${corporate_sales_data_month_testing[10]},${corporate_sales_data_month_testing[11]},0],fill:false}]}}" />`
              `<p>Overall Monthly Sale</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month}],fill:false}]}}" /><p>Overall Monthly Sale Piechart</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'pie',data:{labels:['Testing Service','Non Testing Service'],datasets:[{data:[${pie_data_month[0].testing},${pie_data_month[0].non_testing}]}]}}" /><p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing}],fill:false}]}}" /><p>Monthly Non Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_non_test}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_non_test}],fill:false}]}}" />`,
          }),
        }
      );
      // let res = await fetch(
      //   "https://beta-hire.equinoxlab.com/api/Candidate_Dashboard.svc/send_mail",
      //   {
      //     method: "POST",
      //     headers: { "Content-Type": "Application/Json" },
      //     body: JSON.stringify({
      //       To: "pratikpaul.equinox@gmail.com",
      //       Subject: "TEST Graphs 1",
      //       MailBody:
      //         // `<p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing[1]},${fnb_sales_data_month_testing[2]},${fnb_sales_data_month_testing[3]},${fnb_sales_data_month_testing[4]},${fnb_sales_data_month_testing[5]},${fnb_sales_data_month_testing[6]},${fnb_sales_data_month_testing[7]},${fnb_sales_data_month_testing[8]},${fnb_sales_data_month_testing[9]},${fnb_sales_data_month_testing[10]},${fnb_sales_data_month_testing[11]},0],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing[1]},${corporate_sales_data_month_testing[2]},${corporate_sales_data_month_testing[3]},${corporate_sales_data_month_testing[4]},${corporate_sales_data_month_testing[5]},${corporate_sales_data_month_testing[6]},${corporate_sales_data_month_testing[7]},${corporate_sales_data_month_testing[8]},${corporate_sales_data_month_testing[9]},${corporate_sales_data_month_testing[10]},${corporate_sales_data_month_testing[11]},0],fill:false}]}}" />`
      //         `<p>Overall Monthly Sale</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month}],fill:false}]}}" /><p>Overall Monthly Sale Piechart</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'pie',data:{labels:['Testing Service','Non Testing Service'],datasets:[{data:[${pie_data_month[0].testing},${pie_data_month[0].non_testing}]}]}}" /><p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing}],fill:false}]}}" /><p>Monthly Non Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_non_test}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_non_test}],fill:false}]}}" />`,
      //     }),
      //   }
      // );
      let data = await res.json();
      dispatch(send_mail_graph_succ());
    } catch (error) {
      dispatch(send_mail_graph_err());
    }
  };

//  GET Client Type Sample Count For Sales Person

export const salesperson_client_type_count_req = () => ({
  type: GET_SALESPERSON_CLIENT_TYPE_COUNT_REQUEST,
});
export const salesperson_client_type_count_succ = (data) => ({
  type: GET_SALESPERSON_CLIENT_TYPE_COUNT_SUCCESS,
  payload: data,
});
export const salesperson_client_type_count_err = () => ({
  type: GET_SALESPERSON_CLIENT_TYPE_COUNT_ERROR,
});

export const get_salesperson_client_type_count_data =
  (
    setLoad,
    department,
    params_count,
    start_date,
    end_date,
    setSample_Count_Data
  ) =>
  async (dispatch) => {
    try {
      dispatch(salesperson_client_type_count_req());
      setLoad(true);
      // let res = await fetch(`${base_url}/salesperson_sample_type_quantity_dummy`, {
      let res = await fetch(`${base_url}/salesperson_sample_type_quantity`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department == "FnB Sales"
              ? "1"
              : department == "Corporate Sales"
              ? "2"
              : "",
          get_date: params_count == "Custom Date" ? "" : params_count,
          start_date: params_count == "Custom Date" ? start_date : "",
          end_date: params_count == "Custom Date" ? end_date : "",
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setSample_Count_Data([]);
        }
        dispatch(salesperson_client_type_count_succ(data?.Data));
        setSample_Count_Data(data?.Data);
      } else {
        setSample_Count_Data([]);
        dispatch(salesperson_client_type_count_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setSample_Count_Data([]);
      setLoad(false);
      dispatch(salesperson_client_type_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  GET Client Type Sample Revenue For Sales Person

export const salesperson_client_type_revenue_req = () => ({
  type: GET_SALESPERSON_CLIENT_TYPE_REVENUE_REQUEST,
});
export const salesperson_client_type_revenue_succ = (data) => ({
  type: GET_SALESPERSON_CLIENT_TYPE_REVENUE_SUCCESS,
  payload: data,
});
export const salesperson_client_type_revenue_err = () => ({
  type: GET_SALESPERSON_CLIENT_TYPE_REVENUE_ERROR,
});

export const get_salesperson_client_type_revenue_data =
  (
    setLoad,
    department,
    params_revenue,
    start_date,
    end_date,
    setSample_Revenue_Data
  ) =>
  async (dispatch) => {
    try {
      dispatch(salesperson_client_type_revenue_req());
      setLoad(true);
      // let res = await fetch(`${base_url}/salesperson_sample_type_amount_dummy`, {
      let res = await fetch(`${base_url}/salesperson_sample_type_amount`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_id:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department == "FnB Sales"
              ? "1"
              : department == "Corporate Sales"
              ? "2"
              : "",
          get_date: params_revenue == "Custom Date" ? "" : params_revenue,
          start_date: params_revenue == "Custom Date" ? start_date : "",
          end_date: params_revenue == "Custom Date" ? end_date : "",
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        if (data?.response?.message == "Data Not Found") {
          setSample_Revenue_Data([]);
        }
        dispatch(salesperson_client_type_revenue_succ(data?.Data));
        setSample_Revenue_Data(data?.Data);
      } else {
        setSample_Revenue_Data([]);
        dispatch(salesperson_client_type_revenue_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response.message,
        });
      }
    } catch (error) {
      setSample_Revenue_Data([]);
      setLoad(false);
      dispatch(salesperson_client_type_revenue_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// Marketing Section For Leadsource

export const category_data_marketing_req = () => ({
  type: CATEGORY_DATA_MARKETING_REQUEST,
});
export const category_data_marketing_succ = (data) => ({
  type: CATEGORY_DATA_MARKETING_SUCCESS,
  payload: data,
});
export const category_data_marketing_err = () => ({
  type: CATEGORY_DATA_MARKETING_ERROR,
});

export const category_data_marketing =
  (
    setCat_Data,
    DEPT,
    selected_cat,
    selected_source,
    select_leadStage,
    setCat_Load
  ) =>
  async (dispatch) => {
    try {
      dispatch(category_data_marketing_req());
      setCat_Load(true);
      let res = await fetch(
        `${base_url}/category_wise_leadsource_marketing_created_on`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
            CATEGORY: selected_cat,
            LEADSOURCE: selected_source,
            STAGE: select_leadStage,
          }),
        }
      );
      let data = await res.json();
      setCat_Load(false);
      if (data?.response?.code == 200) {
        dispatch(category_data_marketing_succ(data?.Data));
        setCat_Data(data?.Data);
      } else {
        setCat_Data([]);
        dispatch(category_data_marketing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setCat_Data([]);
      setCat_Load(false);
      dispatch(category_data_marketing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

export const category_updated_on_marketing_req = () => ({
  type: CATEGORY_UPDATED_ON_MARKETING_REQUEST,
});
export const category_updated_on_marketing_succ = (data) => ({
  type: CATEGORY_UPDATED_ON_MARKETING_SUCCESS,
  payload: data,
});
export const category_updated_on_marketing_err = () => ({
  type: CATEGORY_UPDATED_ON_MARKETING_ERROR,
});

export const category_updated_on_marketing =
  (
    setCat_Data,
    DEPT,
    selected_cat,
    selected_source,
    select_leadStatus,
    setCat_Load
  ) =>
  async (dispatch) => {
    try {
      dispatch(category_updated_on_marketing_req());
      setCat_Load(true);
      // let res = await fetch(`${base_url}/category_wise_leadsource_marketing_updated_on`, {
      let res = await fetch(
        `${base_url}/category_wise_leadsource_marketing_updated_on`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
            CATEGORY: selected_cat,
            LEADSOURCE: selected_source,
            STATUS: select_leadStatus,
          }),
        }
      );
      let data = await res.json();
      setCat_Load(false);
      if (data?.response?.code == 200) {
        dispatch(category_updated_on_marketing_succ(data?.Data));
        setCat_Data(data?.Data);
      } else {
        setCat_Data([]);
        dispatch(category_updated_on_marketing_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setCat_Data([]);
      setCat_Load(false);
      dispatch(category_updated_on_marketing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

export const category_revenue_marketing_req = () => ({
  type: CATEGORY_REVENUE_MARKETING_REQUEST,
});
export const category_revenue_marketing_succ = (data) => ({
  type: CATEGORY_REVENUE_MARKETING_SUCCESS,
  payload: data,
});
export const category_revenue_marketing_err = () => ({
  type: CATEGORY_REVENUE_MARKETING_ERROR,
});

export const category_revenue_marketing =
  (setCat_Data, DEPT, selected_cat, selected_source) => async (dispatch) => {
    try {
      dispatch(category_revenue_marketing_req());
      let res = await fetch(`${base_url}/order_amount_leadsource_category`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          DEPARTMENT:
            DEPT == "Tech" ||
            DEPT == "Admin" ||
            DEPT == "Management" ||
            DEPT == "Marketing"
              ? "ALL"
              : DEPT,
          CATEGORY: selected_cat,
          LEADSOURCE: selected_source,
        }),
      });
      let data = await res.json();
      if (data?.response?.code == 200) {
        dispatch(category_revenue_marketing_succ(data?.Data));
        setCat_Data(data?.Data);
      } else {
        dispatch(category_revenue_marketing_err());
        setCat_Data([]);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setCat_Data([]);
      dispatch(category_revenue_marketing_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

export const category_count_req = () => ({
  type: CATEGORY_COUNT_REQUEST,
});
export const category_count_succ = (data) => ({
  type: CATEGORY_COUNT_SUCCESS,
  payload: data,
});
export const category_count_err = () => ({
  type: CATEGORY_COUNT_ERROR,
});

export const category_count =
  (setCat_Data, DEPT, selected_cat, selected_source, setCount_Load) =>
  async (dispatch) => {
    try {
      dispatch(category_count_req());
      setCount_Load(true);
      let res = await fetch(`${base_url}/order_count_leadsource_category`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          DEPARTMENT:
            DEPT == "Tech" ||
            DEPT == "Admin" ||
            DEPT == "Management" ||
            DEPT == "Marketing"
              ? "ALL"
              : DEPT,
          CATEGORY: selected_cat,
          LEADSOURCE: selected_source,
        }),
      });
      let data = await res.json();
      setCount_Load(false);
      if (data?.response?.code == 200) {
        dispatch(category_count_succ(data?.Data));
        setCat_Data(data?.Data);
      } else {
        dispatch(category_count_err());
        setCat_Data([]);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setCount_Load(false);
      setCat_Data([]);
      dispatch(category_count_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

export const marketing_performance_req = () => ({
  type: MARKETING_PERFORMANCE_REQUEST,
});
export const marketing_performance_succ = (data) => ({
  type: MARKETING_PERFORMANCE_SUCCESS,
  payload: data,
});
export const marketing_performance_err = () => ({
  type: MARKETING_PERFORMANCE_ERROR,
});

export const marketing_performance =
  (
    setLeadsourceProgressionData,
    selectedCategory,
    selectedLeadsource,
    params,
    start_date,
    end_date,
    setData_load,
    setTotal_Leads
  ) =>
  async (dispatch) => {
    try {
      dispatch(marketing_performance_req());
      setData_load(true);
      let res = await fetch(`${base_url}/get_marketing_new_leads`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          // DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
          CATEGORY: selectedCategory,
          LEADSOURCE: selectedLeadsource,
          GET_DATE: params == "Custom Date"? "" : params,
          START_DATE: params == "Custom Date"?start_date:"",
          END_DATE: params == "Custom Date" ?end_date:"",
        }),
      });
      let data = await res.json();
      if (data?.response?.code == 200) {
        setData_load(false);
        dispatch(marketing_performance_succ(data?.Data));
  

        let temp_data = [];
        for(let i=0; i<data?.Data?.length; i++){
         if(data?.Data[i]?.category != "Fnb Zomato Lead" && data?.Data[i]?.leadsource != "Fnb Zomato Lead"){
           temp_data?.push(data?.Data[i])
         }
        }
        setLeadsourceProgressionData(temp_data);

   
        if(temp_data?.length>0){
    let new_lead = temp_data?.reduce((acc, ele) => {
          return acc + Number(ele?.new_lead);
        }, 0);
        let relevant_lead = temp_data?.reduce((acc, ele) => {
          return acc + Number(ele?.relevant);
        }, 0);
        let customer_lead = temp_data?.reduce((acc, ele) => {
          return acc + Number(ele?.customer);
        }, 0);

        setTotal_Leads(Number(new_lead)+ Number(relevant_lead) + Number(customer_lead))
        }
    


      } else {
        dispatch(marketing_performance_err());
        setLeadsourceProgressionData([]);
        setData_load(false);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setData_load(false);
      setLeadsourceProgressionData([]);
      dispatch(marketing_performance_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// get weekly client data

export const weekly_client_data_req = () => ({
  type: GET_WEEKLY_CLIENT_REQUEST,
});
export const weekly_client_data_succ = (data) => ({
  type: GET_WEEKLY_CLIENT_SUCCESS,
  payload: data,
});
export const weekly_client_data_err = () => ({
  type: GET_WEEKLY_CLIENT_ERROR,
});

export const get_weekly_client_data =
  (department, setLoad, setData, setWeekly_Dates,send_month, send_year) => async (dispatch) => {
    setLoad(true);
    try {
      dispatch(weekly_client_data_req());
      // let res = await fetch(`${base_url}/weekly_client_data_department_year`, {
      let res = await fetch(`${base_url}/weekly_new_client`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          dept_name:
            department == "Tech" ||
            department == "Admin" ||
            department == "Management"
              ? "ALL"
              : department,
              "year_param":send_year,
              "month_param":send_month
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data?.response?.code == 200) {
        setData(data?.Data);
        setWeekly_Dates(data?.Data[0]?.dates);
        dispatch(weekly_client_data_succ(data?.Data));
      } else {
        setData([]);
        dispatch(weekly_client_data_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setData([]);
      setLoad(false);
      dispatch(weekly_client_data_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

// get qc utilization data

export const qc_utilization_data_req = () => ({
  type: GET_QC_DATA_REQUEST,
});
export const qc_utilization_data_succ = (data) => ({
  type: GET_QC_DATA_SUCCESS,
  payload: data,
});
export const qc_utilization_data_err = () => ({
  type: GET_QC_DATA_ERROR,
});

export const get_qc_utilization_data =
  (setLoad, setData) => async (dispatch) => {
    setLoad(true);
    try {
      dispatch(qc_utilization_data_req());
      // let res = await fetch(`${base_url}/qc_utilization_data_department_year`, {
      let res = await fetch(`${base_url}/qc_utilization`);
      let data = await res.json();
      setLoad(false);
      if (data?.response?.code == 200) {
        setData(data?.Data);
        dispatch(qc_utilization_data_succ(data?.Data));
      } else {
        setData([]);
        dispatch(qc_utilization_data_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      setData([]);
      setLoad(false);
      dispatch(qc_utilization_data_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };


  