import React, { useEffect, useState } from "react";
import eq_logo from "../Media/equinox_logo.png";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import Total_leads_tbl from "../components/Total_leads_tbl";
import report_loader from "../Media/ezgif.com-optimize.gif";
import moment from "moment";

function Leads_leader() {
  const [salesperson_data, setSalesperson_Data] = useState([]);
  const [assigned_data, setAssigned_Data] = useState([]);
  const [leads_added_data, setLeads_Added_Data] = useState([]);

  const [salesperson_load, setSalesperson_Load] = useState(false);
  const [assigned_load, setAssigned_Load] = useState(false);
  const [leads_added_load, setLeads_added_Load] = useState(false);

  const [total_leads,setTotal_Leads] = useState(0);
  const [total_converted,setTotal_Converted] = useState(0);
  const [total_conversion,setTotal_Conversion] = useState(0);
  
  const [assigned_total,setAssigned_Total] = useState(0);
  const [assigned_converted,setAssigned_Converted] = useState(0);
  const [assigned_conversion,setAssigned_Conversion] = useState(0);

  const [added_total,setAdded_Total] = useState(0);
  const [added_converted,setAdded_Converted] = useState(0);
  const [added_conversion,setAdded_Conversion] = useState(0)

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const [fromDate, setFromDate] = useState(new Date(y, m, 1));
  const [toDate, setToDate] = useState(new Date(y, m + 1, 0));

  const get_converted_leads_salesperson = async () => {
    setSalesperson_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/total_converted_leads_by_salesperson`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(fromDate).format("YYYY-MM-DD"),
            END_DATE: moment(toDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setSalesperson_Load(false);
      setSalesperson_Data(data?.Data);

      let total_lead = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)

     setTotal_Leads(total_lead);
     setTotal_Converted(total_converted);
     let temp = (total_converted/total_lead)*100;

     setTotal_Conversion(temp?.toFixed(2))

    } catch (error) {
      setSalesperson_Load(false);
    }
  };

  const get_leads_assigned_marketing = async () => {
    setAssigned_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/leads_assigned_by_marketing`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(fromDate).format("YYYY-MM-DD"),
            END_DATE: moment(toDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setAssigned_Load(false);
      setAssigned_Data(data?.Data);
      let total_assigned = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)
    setAssigned_Total(total_assigned)
    setAssigned_Converted(total_converted)
    let temp = (total_converted/total_assigned)*100
    setAssigned_Conversion(temp?.toFixed(2))
    } catch (error) {
      setAssigned_Load(false);
    }
  };

  const get_leads_added_corporate = async () => {
    setLeads_added_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/leads_added_by_corporate`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(fromDate).format("YYYY-MM-DD"),
            END_DATE: moment(toDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setLeads_added_Load(false);
      setLeads_Added_Data(data?.Data);
      let total_lead = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)

     setAdded_Total(total_lead);
     setAdded_Converted(total_converted);
     let temp = (total_converted/total_lead)*100;

     setAdded_Conversion(temp?.toFixed(2))


    } catch (error) {
      setLeads_added_Load(false);
    }
  };


  useEffect(() => {
    get_converted_leads_salesperson();
    get_leads_assigned_marketing();
    get_leads_added_corporate();
  }, []);
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
<div className="row">
  <h4 style={{textAlign:"center",fontSize:"30px"}}>Leads Leader</h4>
</div>
<div className="row mt-4">
  <div className="col-sm-12 col-md-6">
  {assigned_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                    <div className="col-3">
                      <p>Lead Assigned</p>
                      <h5>{assigned_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{assigned_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{assigned_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3 mb-5">
                    <Total_leads_tbl
                      header_name={"Leads Assigned Marketing"}
                      data={assigned_data}
                    />
                  </div>
                
                </>
              )}
  </div>
  <div className="col-sm-12 col-md-6">
  {salesperson_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                 <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Total Lead</p>
                      <h5>{total_leads}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{total_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{total_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Total Leads"}
                      data={salesperson_data}
                    />
                  </div>
                 
                </>
              )}
  </div>
</div>
<div className="row mt-4 mb-5">
  <div className="col-sm-12 col-md-6">
  {leads_added_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Lead Added</p>
                      <h5>{added_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{added_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{added_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Leads Added"}
                      data={leads_added_data}
                    />
                  </div>
                
                </>
              )}
  </div>
</div>

        {/* <div className="row">
          <div className="col-6">
            <div className="row" id={styles.header_contain}>
              <img id={styles.logo_img} src={eq_logo} alt="" />
              <p>Corporate Sales</p>
              <div>
                <p>Leads Leader</p>
              </div>
            </div>

            <div className="row mt-5">
              {assigned_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                    <div className="col-3">
                      <p>Lead Assigned</p>
                      <h5>{assigned_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{assigned_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{assigned_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3 mb-5">
                    <Total_leads_tbl
                      header_name={"Leads Assigned Marketing"}
                      data={assigned_data}
                    />
                  </div>
                
                </>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {salesperson_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                 <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Total Lead</p>
                      <h5>{total_leads}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{total_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{total_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Total Leads"}
                      data={salesperson_data}
                    />
                  </div>
                 
                </>
              )}
            </div>
            <div className="row mt-5 mb-5">
              {leads_added_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Lead Added</p>
                      <h5>{added_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{added_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{added_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Leads Added"}
                      data={leads_added_data}
                    />
                  </div>
                
                </>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Leads_leader;
