import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { transection_report } from "../Redux/actions";
import styles from "../Styles/Table.module.css"

function Transaction_report() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id"); 

  const [transectionData, setTransectionData] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  useEffect(() => {
    if(Role_id != ""){
      dispatch(transection_report(setTransectionData));
    }
  }, []);

  // const columns = [
  //   {
  //     name: "Day",
  //     label: "Entity",
  //     options: {
  //       filter: false,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Adjusted",
  //     label: "Adjusted",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       download: false,
  //       customBodyRender: (row) => {
  //         if(row == "0"){
  //           return <div style={{margin:"auto"}}><span style={{ textAlign:"left", marginTop: "15px" }}>0</span></div>
  //         }
  //         return (
  //           <span style={{ textAlign:"center", marginTop: "15px" }}>
  //             {" "}
  //             &#8377; {new Intl.NumberFormat("en-IN").format(row)}
  //           </span>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "Approved",
  //     label: "Approved",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       customBodyRender: (row) => {
  //         if(row == "0"){
  //           return <div style={{margin:"auto"}}><span style={{ textAlign:"left", marginTop: "15px" }}>0</span></div>
  //         }
  //         return (
  //           <span style={{ textAlign:"center", marginTop: "15px" }}>
  //             {" "}
  //             &#8377; {new Intl.NumberFormat("en-IN").format(row)}
  //           </span>
  //         );
  //       },
  //     },
  //   },

  //   {
  //     name: "Pending",
  //     label: "Pending",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       customBodyRender: (row) => {
  //         if(row == "0"){
  //           return <div style={{margin:"auto"}}><span style={{ textAlign:"left", marginTop: "15px" }}>0</span></div>
  //         }
  //         return (
  //           <span style={{ textAlign:"center", marginTop: "15px" }}>
  //             {" "}
  //             &#8377; {new Intl.NumberFormat("en-IN").format(row)}
  //           </span>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "Rejected",
  //     label: "Rejected",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       customBodyRender: (row) => {
  //         if(row == "0"){
  //           return <div style={{margin:"auto"}}><span style={{ textAlign:"left", marginTop: "15px" }}>0</span></div>
  //         }
  //         return (
  //           <div style={{margin:"auto"}}>

  //           <span style={{ textAlign:"left", marginTop: "15px" }}>
  //             {" "}
  //             &#8377; {new Intl.NumberFormat("en-IN").format(row)}
  //           </span>
  //           </div>
  //         );
  //       },
  //     },
  //   },
  // ];
  const columns=[
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
         
                {/* <div > */}
                  <p
                    style={{display: "flex",
                    alignItems: "center" ,fontSize: "15px",
                    marginTop: "8px",
                    marginBottom: "0.4rem",marginLeft:"4%"}}
                  >
                     Status
                 
                  </p>{" "}
                {/* </div> */}
              
            </>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            minWidth:"100px",
            maxWidth:"100px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000
          }
        }),
  
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "-2%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,

        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,

        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //          color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        // setCellProps: (cellValue) => ({
        //   style: {
        //     backgroundColor:
        //       Number(cellValue?.props?.children) >= 0 &&
        //       Number(cellValue?.props?.children) < 20
        //         ? "#dff3ff"
        //         : Number(cellValue?.props?.children) >= 20 &&
        //           Number(cellValue?.props?.children) < 40
        //         ? "#9fdcff"
        //         : Number(cellValue?.props?.children) >= 40 &&
        //           Number(cellValue?.props?.children) < 60
        //         ? "#60c5ff"
        //         : Number(cellValue?.props?.children) >= 60 &&
        //           Number(cellValue?.props?.children) < 80
        //         ? "#20aeff"
        //         : Number(cellValue?.props?.children) >= 80 &&
        //           Number(cellValue?.props?.children) <= 600
        //         ? "#008edf"
        //         : Number(cellValue?.props?.children) >= 600
        //         ? "#1D5D9B"
        //         : "white",
        //         color:((Number(cellValue?.props?.children) >= 80 &&
        //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
        //   },
        // }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ]

  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img id={styles.loader_sample} src={report_loader} />
      ) : (
        <>
          <div
           className={styles.transaction_tbl}
            style={{
              marginLeft: "2%",
              marginTop: "2%",
              width: "96%",
              marginBottom: "3%",
            }}
          >
            <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Transaction Report</h5>}
              data={transectionData}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                tableBodyMaxHeight: "60vh",
                pagination:false,
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
                sortOrder: {
                  name: 'Q',
                  direction: 'desc'
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
export default Transaction_report;
