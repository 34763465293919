import React, { useRef } from 'react'
// import {
//     ComposedChart,
//     Line,
//     Area,
//     Bar,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
//     BarChart,
//   } from 'recharts';


  import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import no_data_icon from "../Media/no_data_icon.gif";
import styles from "../Styles/Sales_analysis.module.css";

function Salespersonwise_sample_graph({data,xLabels}) {

  const windowHeight = useRef(window.innerHeight);
               {/* <BarChart
      dataset={data}
      yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
      series={[{ dataKey: 'seoul', label: 'Seoul rainfall', valueFormatter }]}
      layout="horizontal"
      {...chartSetting}
    /> */}
    const chartSetting = {
      xAxis: [
        {
          label: 'Sample Count',
        },
      ],
      width: 500,
      height: undefined

    };

  return (


  //   <div id="invoice_graph">
  //   {data?.length > 0 ? (
  //     <BarChart
  //     xAxis={[
  //       {
  //         scaleType: "band",
  //         label: "Sales Person",
  //       },
  //     ]}
  //     yAxis={[
  //       {
  //         scaleType: "band",
  //         data: xLabels,
  //         label: "Invoice Amount",    
  //       },
  //     ]}
  //     series={[
  //       {
  //         data: data,
  //         label: "Invoice Amount",
        
  //         color: "#007cc3",
  //       },
  //     ]}
  //     layout="horizontal"
  //     width={700}
  //     height={400}
  //     margin={{ left: 100, bottom: 100 }}
  //     />
  //   ) : (
  //     <>
  //       {" "}
  //       <div
  //         className={styles.noData}
  //         style={{ marginLeft: "-1%", marginTop: "0%" }}
  //       >
  //         <img
  //           src={no_data_icon}
  //           alt=""
  //           style={{ height: "200px", width: "200px" }}
  //         />
  //         <h4>No Data Added</h4>
  //       </div>
  //     </>
  //   )}
  // </div>
  <>
{data?.length>0?
<BarChart
      dataset={data}
      yAxis={[{ scaleType: 'band',label: "Salesperson", dataKey: 'sales_person',
        // valueFormatter: (value) => value.split(" ").join("\n"),
       }]}
      series={[{ dataKey: 'sample_count', label: 'Sales person - Wise Sample',color:"#007cc3" }]}
      layout="horizontal"
      grid={{ vertical: true }}
       barLabel="value"
       sx={{
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: "translate(-125px, 0)",
          fontWeight: "bold",
        },
        [`.${axisClasses.bottom} .${axisClasses.label}`]: {
          fontWeight: "bold",
          transform: "translate(0, 5px)",
          marginTop: "20px",
        },
      }}
       margin={{left:windowHeight.current<650?130:80}}
      {...chartSetting}
    />:<></>}
    </>
  )
}

export default Salespersonwise_sample_graph