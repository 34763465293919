import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../Styles/Table.module.css";
import MiniDrawer from "../components/Minidrawer";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { hub_wise_lab_data } from "../Redux/actions";
import MUIDataTable from "mui-datatables";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function Hub_wise_lab() {
  const [hub_lab_data, setHub_lab_data] = useState([]);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const check_loading = useSelector((state) => state?.data?.loading);
  const [hub_list, setHub_List] = useState([]);
  const [selected_hub, setSelected_Hub] = useState("");

  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);


  const get_hub_list = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/hub_list");
      let data = await res.json();
      setHub_List(data?.Data);
    } catch (error) {
      ;
    }
  };
  useEffect(() => {
    //  dispatch(hub_wise_lab_data(setHub_lab_data,"1"))
    if(Role_id != ""){
      get_hub_list();
    }
  }, []);

  const handleHub = (e) => {
    setSelected_Hub(e.target.value);
    if (e.target.value != "") {
      dispatch(hub_wise_lab_data(setHub_lab_data, e.target.value));
    }
  };

  const get_total =(val)=>{

  //   if(val == "D"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D)
  //    },0)
  //    return total_count;
  //  }
  //    else if(val == "D1"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D1)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "D2"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D2)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "D3"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D3)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "D4"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D4)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "D5"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D5)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "D6"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.D6)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W1"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W1)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W2"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W2)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W3"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W3)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W4"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W4)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W5"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W5)
  //    },0)
  //    return total_count;
  //  }
  //  else if(val == "W6"){
  //     let total_count = hub_lab_data?.reduce((acc,ele)=>{
  //       return acc+Number(ele?.W6)
  //    },0)
  //    return total_count;
  //  }
    if(val == "M"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M)
    },0)
    return total_count;
  }
  else if(val == "M1"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M1)
    },0)
    return total_count;
  }
  else if(val == "M2"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M2)
    },0)
    return total_count;
  }
  else if(val == "M3"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M3)
    },0)
    return total_count;
  }
  else if(val == "M4"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M4)
    },0)
    return total_count;
  }
  else  if(val == "M5"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M5)
    },0)
    return total_count;
  }
  else if(val == "M6"){
     let total_count = hub_lab_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.M6)
    },0)
    return total_count;
  }
  else if(val == "Q"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q)
  },0)
  return total_count;
  }
  else if(val == "Q1"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q1)
  },0)
  return total_count;
  }
  else if(val == "Q2"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q2)
  },0)
  return total_count;
  }
  else if(val == "Q3"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q3)
  },0)
  return total_count;
  }
  else if(val == "Q4"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q4)
  },0)
  return total_count;
  }
  else if(val == "Q5"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q5)
  },0)
  return total_count;
  }
  else if(val == "Q6"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Q6)
  },0)
  return total_count;
  }
  else if(val == "Y"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y)
  },0)
  return total_count;
  }
  else if(val == "Y1"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y1)
  },0)
  return total_count;
  }
  else if(val == "Y2"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y2)
  },0)
  return total_count;
  }
  else if(val == "Y3"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y3)
  },0)
  return total_count;
  }
  else if(val == "Y4"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y4)
  },0)
  return total_count;
  }
  else if(val == "Y5"){
   let total_count = hub_lab_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.Y5)
  },0)
  return total_count;
  }

  else if(val == "Y6"){
    let total_count = hub_lab_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y6)
   },0)
   return total_count;
  }
  else{
     let x= 1;
   }
  
  }
  

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "Lab_Type",
      label: "Lab Type",
      options: {
        filter: true,
        sort: false,
        // customHeadRender: ()=>{
        //   return<div style={{backgroundColor:"#007cc3", padding:"10px",textAlign:"left"}}><p style={{marginTop:"3%"}}>Lead Category</p></div>
        // },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            minWidth: "200px",
            maxWidth: "200px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },

    // {
    //   name: "D",
    //   label: "D",
    //   options: {
    //     filter: false,
    //     sort: true,

    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //         color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),

    //     customBodyRender: (row) => {
    //       // return <div><p>{row}</p></div>;
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D1",
    //   label: "D1",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row,data) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D2",
    //   label: "D2",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D3",
    //   label: "D3",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D4",
    //   label: "D4",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D5",
    //   label: "D5",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "D6",
    //   label: "D6",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W",
    //   label: "W",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W1",
    //   label: "W1",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W2",
    //   label: "W2",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W3",
    //   label: "W3",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W4",
    //   label: "W4",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W5",
    //   label: "W5",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "W6",
    //   label: "W6",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) >= 0 &&
    //           Number(
    //             cellValue?.props?.children?.props?.children
    //           ) < 10000
    //             ? "#dff3ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 10000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 20000
    //             ? "#9fdcff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 20000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 40000
    //             ? "#60c5ff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 40000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 50000
    //             ? "#20aeff"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 50000 &&
    //               Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) < 60000
    //             ? "#008edf"
    //             : Number(
    //                 cellValue?.props?.children?.props?.children
    //               ) >= 60000
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //            color:((Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 50000 &&
    //         Number(
    //           cellValue?.props?.children?.props?.children
    //         ) < 60000) || Number(
    //           cellValue?.props?.children?.props?.children
    //         ) >= 60000)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return (
    //         <div style={{ textAlign: "center", padding: "10px" }}>
    //           <p style={{ marginLeft: "20%" }}>{row}</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        // customHeadLabelRender: () => {
        //   return (
        //     <>
        //       {!show ? (
        //         <div style={{ display: "flex" }} onClick={() => setShow(true)}>
        //           <p
        //             style={{
        //               display: "flex",
        //               alignItems: "center",
        //               fontSize: "15px",
        //               marginTop: "8px",
        //               marginBottom: "0.4rem",
        //             }}
        //           >
        //             Q <ArrowDownwardIcon sx={{ height: "15px" }} />
        //           </p>{" "}
        //         </div>
        //       ) : (
        //         <p
        //           style={{
        //             verticalAlign: "middle",
        //             marginTop: "0",
        //             marginBottom: "0.1rem",
        //           }}
        //         >
        //           Q
        //         </p>
        //       )}
        //     </>
        //   );
        // },
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row,data) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.props?.children
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.props?.children
              ) < 10000
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 10000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 20000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 20000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 40000
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 40000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 50000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 50000 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 60000
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 60000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
               color:((Number(
              cellValue?.props?.children?.props?.children
            ) >= 50000 &&
            Number(
              cellValue?.props?.children?.props?.children
            ) < 60000) || Number(
              cellValue?.props?.children?.props?.children
            ) >= 60000)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        {check_loading ? (
          <img
            src={report_loader}
            id={styles.loader}
            style={{ marginTop: "6em" }}
          />
        ) : (
          <div style={{ margin: "auto" }} id={hub_lab_data?.length == 0?"":"table_s6"}>
            {" "}
            <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Hub Wise Report Released</h5>}
              data={hub_lab_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "60vh",
                // rowsPerPageOptions:[28,50],
                rowsPerPage: "28",
                pagination: false,
                sortOrder: {
                  name: 'Q',
                  direction: 'desc'
                },
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: selected_hub == ""?"Please Select Hub":"Data Not Available",
                  },
                },
                customTableBodyFooterRender: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage,
                  data
                ) => {
                  const startIndex = page * rowsPerPage;
                  const endIndex = (page + 1) * rowsPerPage;
                  return (
                    <TableFooter>
                      <TableRow id={styles.table_footer}>
                        <TableCell  id={styles.first_col}>
                          <p >Total</p> 
                        </TableCell>
                        {/* <TableCell>
                          <p >{get_total("D")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("D6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("W6")}</p> 
                        </TableCell> */}
                        <TableCell>
                          <p >{get_total("M")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y6")}</p> 
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                },
                customToolbar: () => {
                  return (
                    <>
                      <select
                        name=""
                        id={styles.dept_drop}
                        onChange={handleHub}
                        value={selected_hub}
                      >
                        <option value="" selected disabled>
                          Select Hub
                        </option>
                        {hub_list?.map((ele) => (
                          <option value={ele?.Hub_Id}>{ele?.Hub_Name}</option>
                        ))}
                      </select>
                    </>
                  );
                },
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Hub_wise_lab;
