import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";

import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";


import { DatePicker, Space } from "antd";
import { daily_invoice_report, get_transaction_data, get_user_list } from "../Latest_addition_redux/actions";
const { RangePicker } = DatePicker;

function Transaction_report_invoice() {
const storage = window.sessionStorage;
let dept = storage.getItem("DEPT");
let user_id = storage.getItem("USER_GUID");
let Role_id = storage.getItem("Role_id");
const [transaction_data,setTransaction_Data] = useState([])
const [invoice_start_date,setInvoice_Start_Date] = useState(dayjs(new Date()))
const [invoice_end_date,setInvoice_End_Date] = useState(dayjs(new Date()))
const check_loading = useSelector((state)=>state.data2?.loading);
const [department,setDepartment] = useState("")
const [user,setUser] = useState("")
const [user_data,setUser_Data] = useState([])
const dispatch = useDispatch();

useEffect(()=>{
  // dispatch(daily_invoice_report(setDaily_Data,user,department,invoice_date))
  if(Role_id != ""){
  if(dept == "FnB Sales"){
    setDepartment("1")
    dispatch(get_user_list(setUser_Data,"1"))
  }
  else if(dept == "Corporate Sales"){
    setDepartment("2")
    dispatch(get_user_list(setUser_Data,"2"))
  }else{
     let x =1;
  }
}
},[])

const handleDept = (e)=>{
   setDepartment(e.target.value)
    dispatch(get_user_list(setUser_Data,e.target.value))
}

const handleSubmit =(e)=>{
  e.preventDefault();
    dispatch(get_transaction_data(setTransaction_Data,user,department,invoice_start_date,invoice_end_date))
}


const handleDateChange = (new_val) =>{
    if(new_val != null){
      setInvoice_Start_Date(new_val[0]);
      setInvoice_End_Date(new_val[1]);
    }
  }

  const columns = [

    {
      name: "added_date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "customer_name",
      label: "customer name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return(
            <>₹{new Intl.NumberFormat("en-IN").format(row)}</>
          )
        }
      },
    },
    {
      name: "reference_no",
      label: "reference no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "transaction_type",
      label: "transaction type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "user_name",
      label: "user name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "comments",
      label: "comments",
      options: {
        filter: false,
        sort: false,
      },
    },




  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div
          style={{
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "3%",
            width: "96%",
          }}
        >
          <MUIDataTable
            title={<h4 style={{ textAlign: "left" }}>Transaction Report (Invoice)</h4>}
            data={transaction_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "600px",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                    <>
                    <div style={{ display: "inline-flex" }}>
                      <form id={styles.form_invoice} onSubmit={handleSubmit}>
                      {user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                      user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ?  <select
                          name=""
                          onChange={handleDept}
                          id={styles.dept_drop_invoice}
                          value={department}
                          required
                        >
                          <option value="" defaultValue disabled>Select Department</option>
                          <option value="1">
                            Sales - Fnb
                          </option>
                          <option value="2">
                            Sales - Corporate
                          </option>
                        </select>:<></>}
                        <select
                        name=""
                        id={styles.user_drop_invoice}
                        onChange={(e)=>setUser(e.target.value)}
                        value={user}
                        required
                      >
                        <option value="" disabled>Please Select User</option>
                        {user_data?.map((ele) => (
                          <option value={ele.salesperson}>{ele.salesperson}</option>
                        ))}
                      </select>
                      <Space direction="vertical" size={12}>
                        <RangePicker
                          // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                          format={"DD-MM-YYYY"}
                          value={[invoice_start_date, invoice_end_date]}
                          onChange={(new_val) => handleDateChange(new_val)}
                        />
                      </Space>
                      <button type="submit" id={styles.submit_invoice}>Submit</button>
                      </form>
                    </div>

                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}


export default Transaction_report_invoice