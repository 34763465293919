import React, { useEffect, useState } from "react";
import Order_value_graph from "../components/Order_value_graph";
import MiniDrawer from "../components/Minidrawer";
import moment from "moment";
import dayjs from "dayjs";
import Order_amt_tbl from "../components/Order_amt_tbl";
import Salesperson_order_tbl from "../components/Salesperson_order_tbl";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";


import { DatePicker, Space } from "antd";

function Monthly_digest() {
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [sales_amount, setSales_Amount] = useState([]);
  const [customer_amount, setCustomer_Amount] = useState([]);
  const [sales_amount_total, setSales_Amount_Total] = useState(0);
  const [current_date_data, setCurrent_date_Data] = useState([]);
  const [current_month_data, setCurrent_Month_data] = useState([]);
  const [xLabels, setxLabels] = useState([]);
  const [graph_data, setGraph_Data] = useState([]);

  const [graph_load, setGraph_Load] = useState(false);
  const [customer_load, setCustomer_Load] = useState(false);
  const [amount_load, setAmount_Load] = useState(false);
  const [month_load, setMonth_Load] = useState(false);
  const [date_load, setDate_Load] = useState(false);


  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");

  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
    }
  };

  const get_amount_salesperson = async () => {
    setAmount_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_amount_by_salesperson",{
          method:"POST",
          headers: {"Content-Type" : "Application/Json"},
          body: JSON.stringify({
            month:send_month,
            year:send_year
          })
        }
      );
      let data = await res.json();
      setAmount_Load(false);
      setSales_Amount(data?.data);
      let total_count = data?.data?.reduce((acc, ele) => {
        return acc + Number(ele?.Total_Amount);
      }, 0);
      setSales_Amount_Total(total_count);
    } catch (error) {
      setAmount_Load(false);
    }
  };

  const get_amount_customer = async () => {
    setCustomer_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_amount_by_customers",{
          method:"POST",
          headers: {"Content-Type" : "Application/Json"},
          body: JSON.stringify({
            month:send_month,
            year:send_year
          })
        }
      );
      let data = await res.json();
      setCustomer_Load(false);
      setCustomer_Amount(data?.data);
    } catch (error) {
      setCustomer_Load(false);
    }
  };

  const get_amount_current_date = async () => {
    setDate_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_amount_by_current_date"
      );
      let data = await res.json();
      setDate_Load(false);
      setCurrent_date_Data(data?.data);
    } catch (error) {
      setDate_Load(false);
    }
  };

  const get_amount_current_month = async () => {
    setMonth_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_amount_by_current_month",{
          method:"POST",
          headers: {"Content-Type" : "Application/Json"},
          body: JSON.stringify({
            month:send_month,
            year:send_year
          })
        }
      );
      let data = await res.json();
      setMonth_Load(false);
      setCurrent_Month_data(data?.data);
    } catch (error) {
      setMonth_Load(false);
    }
  };

  const get_amount_by_date_graph = async () => {
    setGraph_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_amount_by_date",{
          method:"POST",
          headers: {"Content-Type" : "Application/Json"},
          body: JSON.stringify({
            month:send_month,
            year:send_year
          })
        }
      );
      let data = await res.json();
      setGraph_Load(false);
      let value_arr = [];
      let label_arr = [];
      for (let i = 0; i < data?.data?.length; i++) {
        value_arr.push(data?.data?.[i]?.Total_Amount);
        label_arr.push(data?.data?.[i]?.date);
      }
      setxLabels(label_arr);
      setGraph_Data(value_arr);
    } catch (error) {
      setGraph_Load(false);
    }
  };

  const handleMonth = (date, dateString) => {
    if(date){
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    }else{
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSelectedMonth(formattedMonth)
      setSend_month(new_month)
      setSend_year(new_year)
    }

    // if (send_year == formattedYear) {
    //   setMonthFlag(true);
    // }
  };

  useEffect(() => {
    get_amount_current_date();
  }, []);

  useEffect(()=>{
    get_amount_salesperson();
    get_amount_customer();
    get_amount_current_month();
    get_amount_by_date_graph();
  },[send_month])

  

  return (
    <>
      <MiniDrawer />
      {month_load || date_load ? (
        <div className="container-fluid pt-3">
          <img
            src={report_loader}
            style={{ marginTop: "5vh" }}
            id={styles.graph_loader}
          />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-9">
              <h4 style={{ textAlign: "left" }}>Monthly Digest</h4>
            </div>
            <div className="col-3">
            <Space direction="vertical">
                  <DatePicker
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
          </div>
          </div>
          <div className="row">
            <div style={{ width: "90vw", overflow: "auto", margin: "auto" }}>
              {graph_load ? (
                <img src={report_loader} id={styles.graph_loader} />
              ) : (
                <Order_value_graph data={graph_data} xLabels={xLabels} />
              )}
            </div>
          </div>

          <div className="row mt-3 mb-5">
            <div className="col-4">
              {customer_load ? (
                <img src={report_loader} id={styles.tbl_loader} />
              ) : (
                <Order_amt_tbl
                  data={customer_amount}
                  sales_amount_total={sales_amount_total}
                />
              )}
            </div>
            <div className="col-4 mb-5">
              {/* <div className="row" id={styles.today_sales}>
                <div className="col-6" id={styles.left_part}>
                  <h5>Sales Today</h5>
                  <div id={styles.left_part_contain}>
                    <p style={{ fontSize: "12px", color: "#007cc3" }}>Total</p>
                    <p style={{ fontSize: "24px", color: "#007cc3" }}>
                      {current_date_data?.[0]?.Total_Amount == undefined
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            current_date_data[0]?.Total_Amount
                          )}
                    </p>
                  </div>
                </div>
                <div className="col-6" id={styles.right_part}>
                  <div>
                    <p>Company Count</p>
                    <p>{current_date_data?.[0]?.Customer_Count}</p>
                  </div>
                  <div style={{marginTop:"8%"}}> 
                    <p>Total Order</p>
                    <p>{current_date_data?.[0]?.Total_Order}</p>
                  </div>
                </div>
              </div> */}

<div className="row" id={styles.today_sales}>
<div className="col-12" id={styles.header_part}>
                  <div id={styles.value_container}>
                    <h5>Sales Today</h5>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#007cc3",
                        marginTop: "3px",
                      }}
                    >
                      Total Amount (₹)
                    </p>
                    <p
                      style={{
                        fontSize: "24px",
                        color: "#007cc3",
                        marginTop: "-2px",
                      }}
                    >
                     {current_date_data?.[0]?.Total_Amount == undefined
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            current_date_data[0]?.Total_Amount
                          )}
                    </p>
                    {/* <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginTop: "-1px",
                      }}
                    >
                      69.7%
                    </p> */}
                  </div>
                </div>
                <div className="col-6" id={styles.min_contain}>
                  <p>Company Count</p>
                  <p style={{ fontSize: "24px" }}>
                    {current_date_data?.[0]?.Customer_Count == undefined
                      ? 0
                      : current_date_data?.[0]?.Customer_Count}
                  </p>
                </div>
                <div className="col-6" id={styles.min_contain}>
                  <p>Total Order</p>
                  <p style={{ fontSize: "24px" }}>
                    {current_date_data?.[0]?.Total_Order == undefined
                      ? 0
                      :current_date_data?.[0]?.Total_Order}
                  </p>
                </div>
              
</div>


              <div className="row mt-3 " id={styles.order_value}>
                <div className="col-12" id={styles.header_part}>
                  <div id={styles.value_container}>
                    <h5>Order Value MTD</h5>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#007cc3",
                        marginTop: "3px",
                      }}
                    >
                      Total Amount (₹)
                    </p>
                    <p
                      style={{
                        fontSize: "24px",
                        color: "#007cc3",
                        marginTop: "-2px",
                      }}
                    >
                      {current_month_data?.[0]?.Total_Amount == undefined
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            current_month_data[0]?.Total_Amount
                          )}
                    </p>
                    {/* <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginTop: "-1px",
                      }}
                    >
                      69.7%
                    </p> */}
                  </div>
                </div>
                <div className="col-4" id={styles.min_contain}>
                  <p>Min (₹)</p>
                  <p style={{ fontSize: "24px" }}>
                    {current_month_data?.[0]?.Min_Amount == undefined
                      ? 0
                      : new Intl.NumberFormat("en-IN").format(
                          current_month_data[0]?.Min_Amount
                        )}
                  </p>
                </div>
                <div className="col-4" id={styles.min_contain}>
                  <p>Average (₹)</p>
                  <p style={{ fontSize: "24px" }}>
                    {current_month_data?.[0]?.Avg_Amount == undefined
                      ? 0
                      : new Intl.NumberFormat("en-IN").format(
                          current_month_data[0]?.Avg_Amount
                        )}
                  </p>
                </div>
                <div className="col-4" id={styles.min_contain}>
                  <p>Max (₹)</p>
                  <p style={{ fontSize: "24px" }}>
                    {current_month_data?.[0]?.Max_Amount == undefined
                      ? 0
                      : new Intl.NumberFormat("en-IN").format(
                          current_month_data[0]?.Max_Amount
                        )}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-4">
              {amount_load ? (
                <img src={report_loader} id={styles.tbl_loader} />
              ) : (
                <Salesperson_order_tbl data={sales_amount} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Monthly_digest;
