import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { get_client_type_revenue_data } from "../Latest_addition_redux/actions";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";

function Client_Type_Revenue() {
  const storage = window.sessionStorage;
  const [client_load,setClient_Load] = useState(false)
  const [client_data,setClient_Data] = useState([]);
  let department = storage.getItem("DEPT");
  let Role_id = storage.getItem("Role_id");

  const dispatch= useDispatch();
  useEffect(()=>{
    if(Role_id != ""){
      dispatch(get_client_type_revenue_data(setClient_Load,setClient_Data,department))
    }
  },[])

  const get_total =(val)=>{

      if(val == "M"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "M1"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M1)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "M2"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M2)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "M3"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M3)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "M4"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M4)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else  if(val == "M5"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M5)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "M6"){
       let total_count = client_data?.reduce((acc,ele)=>{
         return acc+Number(ele?.M6)
      },0)
      return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q1"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q1)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q2"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q2)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q3"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q3)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q4"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q4)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q5"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q5)
    },0)
    return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Q6"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Q6)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y1"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y1)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y2"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y2)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y3"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y3)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y4"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y4)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y5"){
     let total_count = client_data?.reduce((acc,ele)=>{
       return acc+Number(ele?.Y5)
    },0)
       return  new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "Y6"){
      let total_count = client_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.Y6)
     },0)
     return new Intl.NumberFormat("en-IN").format(total_count);
    }
    else{
       let x= 1;
     }
    
    
    }
    

  const options = {
    filterType: "checkbox",
  };

  const columns = [
    {
      name: "Client_Type",
      label: "Client Type",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            minWidth: "210px",
            maxWidth: "210px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                   padding:"10px",
             color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"     
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
            <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
                   <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
         style: {
            backgroundColor:
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) >= 0 &&
              Number(
                cellValue?.props?.children?.replaceAll(",","")
              ) < 500
                ? "#dff3ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 1500 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 2000
                ? "#20aeff"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2000 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) <= 2500
                ? "#008edf"
                : Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
                             padding:"10px",
                 color: ((Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2000 &&
            Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) <= 2500)|| ( Number(
              cellValue?.props?.children?.replaceAll(",","")
            ) >= 2500))?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return (
            // <div style={{ textAlign: "center", padding: "10px" }}>
            //   <p style={{ marginLeft: "20%" }}>{row}</p>
            // </div>
        <>{new Intl.NumberFormat("en-IN").format(row)}</>
          );
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      <div className='container-fluid pt-3'>
         { client_load ? <img src={report_loader} id={styles.loader} />:
     <div className="row" id={client_data?.length == 0?"":"table_s6"} >

<MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>Client Type Revenue</h5>
            }
            data={client_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              // tableBodyMaxHeight: "60vh",
              rowsPerPageOptions:[10,25,50,100],
              sortOrder: {
                name: 'Q',
                direction: 'desc'
              },
           
                customTableBodyFooterRender: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage,
                  data
                ) => {
                  const startIndex = page * rowsPerPage;
                  const endIndex = (page + 1) * rowsPerPage;
                  return (
                    <TableFooter>
                      <TableRow id={styles.table_footer}>
                      <TableCell  
                     id={styles.first_col_client}>
                <p>Total</p>
                      </TableCell>
                
                
                        <TableCell>
                          <p >{get_total("M")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y6")}</p> 
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                },
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
          </div>}
    </div>
      {/* {client_load ?
        <img src={report_loader} id={styles.loader} />
       : <div style={{ width: "98%", marginLeft: "1%", marginTop: "1%" }} id={client_data.length == 0?"":"table_s6"}>
        <MUIDataTable
          title={<h5 style={{ textAlign: "left" }}>Client Type Revenue</h5>}
          data={client_data}
          columns={columns}
          options={{
            options: options,
            selectableRows: "none",
            viewColumns: false,
            filter: false,
            print: false,
            responsive: "standard",
            pagination:false,
            download: false,
            fixedHeader: true,
            tableBodyMaxHeight: "60vh",
            setTableProps: () => {
              return {
                padding: 0,
              };
            },
            customTableBodyFooterRender: (
              count,
              page,
              rowsPerPage,
              changeRowsPerPage,
              changePage,
              data
            ) => {
              const startIndex = page * rowsPerPage;
              const endIndex = (page + 1) * rowsPerPage;
              return (
                <TableFooter>
                <TableRow id={styles.table_footer}>
                <TableCell  
               id={styles.first_col}>
          <p >Total</p>
                </TableCell>
                
                  <TableCell>
                    <p >{get_total("M")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M1")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M2")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M3")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M4")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M5")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("M6")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q1")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q2")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q3")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q4")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q5")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Q6")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y1")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y2")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y3")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y4")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y5")}</p> 
                  </TableCell>
                  <TableCell>
                    <p >{get_total("Y6")}</p> 
                  </TableCell>
                </TableRow>
              </TableFooter>
              );
            },
            textLabels: {
              body: {
                noMatch: "Data Not Available",
              },
            },
          }}
        />
      </div>} */}
    </>
  );
}

export default Client_Type_Revenue;
