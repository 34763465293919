import React, { useEffect, useState } from "react";
// import MiniDrawer from "../components/Sidebar";
import MUIDataTable from "mui-datatables";
// import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../components/Minidrawer";
import { useDispatch, useSelector } from "react-redux";
import { team_s6_data } from "../Redux/actions";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/HR_Team.module.css";

function HR_Team_S6() {

    const storage = window.sessionStorage;
    let USER_GUID = storage.getItem("USER_GUID");
    let Role_id = storage.getItem("Role_id");

    const navigate = useNavigate();

    const [load, setLoad] = useState(false);
    const [s6_data, setTeam_s6] = useState([])

    const dispatch = useDispatch();
    const data = useSelector((state) => state.data.data);
    const check_loading = useSelector((state) => state.data.loading);
    const check_error = useSelector((state) => state.data.error);

    useEffect(() => {
      if(Role_id != ""){
        dispatch(team_s6_data(setTeam_s6));
      }
      }, []);


      const columns = [
        {
          name: "Legend",
          label: "Stage",
          options: {
            filter: true,
            sort: false,
            setCellProps: () => ({
              style: {
                borderRight:"2px solid grey",
                whiteSpace: "nowrap",
                position: "sticky",
                left: "0",
                background: "white",
                zIndex: 100,
                minWidth: "170px",
                maxWidth: "170px",
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 101,
                
              },
            }),
            customHeadLabelRender: () => {
              return (
                <>
                    <div style={{ display: "flex",justifyContent:"center", }}>
                      <p
                        style={{display: "flex",
                        justifyContent:"center",
                        alignItems: "center" ,
                        fontSize: "15px",
                        marginTop: "8px",
                        marginBottom: "0.4rem"}}
                      >
                        Stage
                      </p>
                    </div>
                </>
              );
            },
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >Stage</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                // <div style={{ textAlign: "left" }}>
                //   <p style={{ marginBottom: "0px", fontWeight:"bold", }}>{row}</p>
                // </div>
                <div style={{ textAlign: "left", padding: "10px", marginTop:"1px",fontWeight:"bold", }}>
                <p style={{ marginBottom: "0px" }}>{row}</p>
              </div>
              );
            },
          },
        },
        {
          name: "D",
          label: "D",
          options: {
            filter: false,
            sort: false,

            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
              
            }),
          
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D1",
          label: "D1",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "#dff3ff",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D2",
          label: "D2",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "dff3ff",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D3",
          label: "D3",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "dff3ff",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D4",
          label: "D4",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D5",
          label: "D5",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "D6",
          label: "D6",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                borderRight:"2px solid grey",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customHeadLabelRender: () => {
              return (
                <>
                    <div style={{ display: "flex",justifyContent:"center", }}>
                      <p
                        style={{display: "flex",
                        justifyContent:"center",
                        alignItems: "center" ,fontSize: "15px",
                        marginTop: "8px",
                        marginBottom: "0.4rem"}}
                      >D6</p>
                    </div>
                </>
              );
            },
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >D6</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px", marginTop:"1px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "WTD",
          label: "W",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W1",
          label: "W1",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                // color: cellValue > 1500 ? "#" : "black",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W2",
          label: "W2",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W3",
          label: "W3",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W4",
          label: "W4",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W5",
          label: "W5",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "W6",
          label: "W6",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                borderRight:"2px solid grey",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customHeadLabelRender: () => {
              return (
                <>
                    <div style={{ display: "flex",justifyContent:"center", }}>
                      <p
                        style={{display: "flex",
                        justifyContent:"center",
                        alignItems: "center" ,fontSize: "15px",
                        marginTop: "8px",
                        marginBottom: "0.4rem"}}
                      >W6</p>
                    </div>
                </>
              );
            },
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >W6</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "MTD",
          label: "M",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M1",
          label: "M1",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M2",
          label: "M2",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M3",
          label: "M3",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M4",
          label: "M4",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M5",
          label: "M5",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M6",
          label: "M6",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                borderRight:"2px solid grey",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customHeadLabelRender: () => {
              return (
                <>
                    <div style={{ display: "flex",justifyContent:"center", }}>
                      <p
                        style={{display: "flex",
                        justifyContent:"center",
                        alignItems: "center" ,fontSize: "15px",
                        marginTop: "8px",
                        marginBottom: "0.4rem"}}
                      >M6</p>
                    </div>
                </>
              );
            },
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >M6</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "QTD",
          label: "Q",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q1",
          label: "Q1",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q2",
          label: "Q2",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q3",
          label: "Q3",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q4",
          label: "Q4",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q5",
          label: "Q5",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Q6",
          label: "Q6",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                borderRight:"2px solid grey",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customHeadLabelRender: () => {
              return (
                <>
                    <div style={{ display: "flex",justifyContent:"center", }}>
                      <p
                        style={{display: "flex",
                        justifyContent:"center",
                        alignItems: "center" ,fontSize: "15px",
                        marginTop: "8px",
                        marginBottom: "0.4rem"}}
                      >Q6</p>
                    </div>
                </>
              );
            },
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >Q6</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y",
          label: "Y",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y1",
          label: "Y1",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y2",
          label: "Y2",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y3",
          label: "Y3",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y4",
          label: "Y4",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y5",
          label: "Y5",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
        {
          name: "Y6",
          label: "Y6",
          options: {
            filter: false,
            sort: false,
            setCellProps: (cellValue) => ({
              style: {
                // borderRight:"2px solid red",
                color:
                  cellValue?.props?.children?.props?.children > 1500
                    ? "white"
                    : "black",
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.props?.children
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.props?.children
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.props?.children
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
              },
            }),
            // customHeadRender: ()=>{
            //   return (
            //   <div 
            //     style={{
            //       backgroundColor:"#007cc3", padding:"5px",textAlign:"center", color:"white", borderRight:"2px solid red", marginTop:"1px"}}>
            //       <p >Y6</p>
            //   </div>)
            // },
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <p style={{ marginBottom: "0px" }}>{row ? row : 0}</p>
                </div>
              );
            },
          },
        },
      ];

    const options = {
        filterType: "checkbox",
      };
  return (
    <>
        <div>
            <MiniDrawer />

            {
                check_loading ? 
                    <img src={report_loader} />
                   :
                  <>
                  
            <div
          style={{ marginTop: "1%", marginLeft: "1%", maxWidth: "98%" }}
          id="table_s6"
        >
          
          <MUIDataTable
          className={styles.teamS6Table}
            title={
              <h5 style={{ textAlign: "left", marginBottom: "0px" }}>
                Team S6
              </h5>
            }
            data={s6_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              search: false,
              // sort: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination: false,
              tableBodyMaxHeight: "550px",
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
                  </>
            }

        
        </div>
    </>
  )
}

export default HR_Team_S6;
