import React from "react";
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

function Corporate_oh_count_graph({ data }) {
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;
  return (
    <div>
      {/* {data?.length>0?  <BarChart
          width={data.length < 8 ? 700 :data.length > 25?3800: data.length > 20 && data?.length <=24?3700: 2000}
          height={450}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: -50,
            bottom: 5,
          }}
        >
          <XAxis dataKey="sales_person" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="current_total_order" stackId="a" fill="#001F3F" />
          <Bar dataKey="current_total_value" stackId="a" fill="#3A6D8C" />
          <Bar dataKey="previous_total_order" stackId="a" fill="#6A9AB0" />
          <Bar dataKey="previous_total_value" stackId="a" fill="#EAD8B1" />
        </BarChart>:<></>} */}
      {data?.length > 0 ? (
        <BarChart
          dataset={data}
          series={[
            {
              dataKey: "current_total_order",
              stack: "assets",
              color: "#001F3F",
              label: "Current month count",
            },
            {
              dataKey: "current_total_value",
              stack: "assets",
              color: "#3A6D8C",
              label: "Current month value",
              valueFormatter: (value) =>
                value == null || value == 0
                  ? null
                  : Number(value) > 99999
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
            },
            {
              dataKey: "previous_total_order",
              stack: "assets",
              color: "#6A9AB0",
              label: "Previous month count",
            },
            {
              dataKey: "previous_total_value",
              stack: "assets",
              color: "#EAD8B1",
              label: "Previous month value",
              valueFormatter: (value) =>
                // (value == null || value == 0) ? null : value.toString(),
                value == null || value == 0
                  ? null
                  : Number(value) > 99999
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
            },
          ]}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "sales_person",
              label: "Sales Person",
              categoryGapRatio: data?.length == 1 ? 0.8 : 0.4,
            },
          ]}
          yAxis={[
            {
              id: "linearAxis",
              scaleType: "linear",
              label: "PMTD-OH count",
              valueFormatter: (value) =>
                currencyFormatter(value / 100000) + "L",
            },
          ]}
          tooltipStyle={{
            width: "100px",
          }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-55px, 0)",
              fontWeight: "bold",
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              fontWeight: "bold",
              transform: "translate(0, 5px)",
              marginTop: "20px",
            },
          }}
          //   slotProps={{ legend: { hidden: true } }}
          width={
            data.length < 8
              ? 700
              : data.length > 25
              ? 3800
              : data.length > 20 && data?.length <= 24
              ? 3700
              : 2000
          }
          height={350}
          margin={{
            left: 100,
            bottom: 70,
          }}
          layout="vertical"
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Corporate_oh_count_graph;
