import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import styles from "../Styles/Table.module.css";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import MiniDrawer from "../components/Minidrawer";

function Relevant_marketing() {
    const formattedMonth = dayjs(new Date());
    let new_month = moment(formattedMonth.$d).format("MM");
    let new_year = moment(formattedMonth.$d).format("YYYY");
    const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
    const [send_month, setSend_month] = useState(new_month);
    const [send_year, setSend_year] = useState(new_year);


    const handleMonth = (date, dateString) => {
        if(date){
          setSelectedMonth(date);
          let formattedMon = moment(date?.$d)?.format("MM");
          let formattedYear = moment(date?.$d)?.format("YYYY");
          setSend_year(formattedYear);
          setSend_month(formattedMon);
        }else{
          const formattedMonth = dayjs(new Date());
          let new_month = moment(formattedMonth.$d).format("MM");
          let new_year = moment(formattedMonth.$d).format("YYYY");
          setSelectedMonth(formattedMonth)
          setSend_month(new_month)
          setSend_year(new_year)
        }
    
      };

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "corporate_lead_count",
          label: `Corporate Lead Count`,
          options: {
            filter: false,
            sort: false,
          },
        },
      
        {
          name: "fnb_lead_count",
          label: `Fnb Lead Count`,
          options: {
            filter: false,
            sort: false,
          },
        },
   
      ];
  return (
    <>
    <MiniDrawer/>
      <div className='container-fluid pt-3'>
        <div className='row mt-3'>
        <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>Relevant Marketing Performance</h5>}
                data={[]}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",

                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customToolbar: () => {
                    return (
                        <>
               <Space direction="vertical">
                  <DatePicker
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
                        </>
                    )
                  },

                //   customTableBodyFooterRender: (
                //       count,
                //       page,
                //       rowsPerPage,
                //       changeRowsPerPage,
                //       changePage,
                //       data
                //     ) => {
                //       const startIndex = page * rowsPerPage;
                //       const endIndex = (page + 1) * rowsPerPage;
                //       return (
                //         <TableFooter id={styles.table_footer}>
                //           <TableRow>
                //             <TableCell id={styles.first_col}>
                //               <p style={{textAlign:"left"}}>Grand Total</p>
                //             </TableCell>
                //             <TableCell><p style={{textAlign:"left"}}>{get_total("count")}</p></TableCell>
                //             <TableCell><p style={{textAlign:"left"}}>{get_total("value")}</p></TableCell>
                          
                //           </TableRow>
                //         </TableFooter>
                //       );
                //     },
                 
                }}
              />
        </div>
      </div>
    </>
  )
}

export default Relevant_marketing
