import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Sales_analysis.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";

import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux/es";
import { operation_report, logistics_report } from "../Redux/actions";
import { DatePicker, Space } from "antd";
import Logistics_Report from "./Logistics_Report";
import Swal from "sweetalert2";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

function Operation_Report() {
  const dateFormat = "DD-MM-YYYY";
  const fromattedDate = dayjs(new Date());
  let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [send_from_date, setSend_from_Date] = useState(new_date);
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [send_to_date, setSend_to_Date] = useState(new_date);
  const [operationData, setOperationData] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [logisticsData, setLogisticsData] = useState([]);
  const [logi_from_date, setLogi_From_Date] = useState(new_date);
  const [logi_to_date, setLogi_To_Date] = useState(new_date);
  const [logistic_load, setLogistic_Load] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  useEffect(() => {
    if (Role_id != "") {
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      dispatch(
        operation_report(setOperationData, new_date, new_date)
      );
      dispatch(
        logistics_report(
          setLogisticsData,
          logi_from_date,
          logi_to_date,
          setLogistic_Load
        )
      );
    }
    // dispatch(logistics_report(setLogisticsData, send_from_date, send_to_date))
  }, []);

  const handleDateChange = (new_val) => {
    // if (new_val !== null) {
    //   setSelectedFromDate(new_val[0]);
    //   setSelectedToDate(new_val[1]);
    //   let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
    //   let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
    //   setSend_from_Date(newStartDate);
    //   setSend_to_Date(newEndDate);
    //   setShowBtn(true);
    // }

    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");

      dispatch(
        operation_report(
          setOperationData,
          newStartDate,
          newEndDate
        )
      );
      // setShowBtn(true);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      dispatch(
        operation_report(
          setOperationData,
          new_date,
          new_date
        )
      );
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedFromDate(dayjs(dates[0]?.$d));
      setSelectedToDate(dayjs(dates[1]?.$d));
      setSend_from_Date(dateStrings[0]);
      setSend_to_Date(dateStrings[1]);
      // setSelectedFromDate(dates[0]?.$d)
      // setSelectedToDate(dates[1]?.$d)

      setShowBtn(true);
    } else {
      setSelectedFromDate(dayjs(new Date()));
      setSelectedToDate(dayjs(new Date()));
      setSend_from_Date(new_date);
      setSend_to_Date(new_date);
      dispatch(operation_report(setOperationData, new_date, new_date));
    }
  };
  const rangePresets = [
    {
      label: "Last Week",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const handleClick = () => {
    if (send_from_date == "" || send_to_date == "") {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Dates",
      });
    } else {
      dispatch(
        operation_report(setOperationData, send_from_date, send_to_date)
      );
      setShowBtn(false);
    }
  };

  const columns = [
    {
      name: "hub_name",
      label: "Hub",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
    {
      name: "vr_count",
      label: "Total VR",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "on_time_count",
      label: "On-Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "delay_count",
      label: "Delayed",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "overdue_count",
      label: "Overdue",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <p style={{ marginLeft: "15%", marginTop: "15px" }}>{row}</p>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img src={report_loader} id={styles.loader_operation} />
      ) : (
        <>
          <div
            className="opertion_tbl"
            style={{ marginLeft: "2%", marginTop: "2%", width: "96%" }}
          >
            <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Scheduling Report</h5>}
              data={operationData}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                pagination: false,
                tableBodyMaxHeight: "60vh",
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <>
                      <div style={{ display: "inline-flex" }}>
                        <Space direction="vertical" size={12}>
                          {/* <RangePicker
                            // defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                            format={dateFormat}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          /> */}
                          {/* <RangePicker
                            presets={rangePresets}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={onRangeChange}
                          /> */}
                            <DateRangePicker
                            format = "dd MMM yyyy"
                            defaultValue={[new Date(), new Date()]}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
                        </Space>
                        {/* <button
                          className="btn btn-primary btn-sm ml-2"
                          onClick={handleClick}
                          style={{
                            display: showBtn ? "block" : "none",
                            marginLeft: "5px",
                            backgroundColor: "white",
                            color: "#007cc3",
                          }}
                        >
                          Apply
                        </button> */}
                      </div>
                    </>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />
          </div>
        </>
      )}
      <Logistics_Report
        logisticsData={logisticsData}
        setLogisticsData={setLogisticsData}
        logistic_load={logistic_load}
        setLogistic_Load={setLogistic_Load}
        send_from_date={send_from_date}
        send_to_date={send_to_date}
        setSend_from_Date={setSend_from_Date}
        setSend_to_Date={setSend_to_Date}
      />
    </>
  );
}
export default Operation_Report;
