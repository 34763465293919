import React from 'react'
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import moment from "moment";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { LineChart } from "@mui/x-charts/LineChart";

function Order_value_graph({data,xLabels}) {
    
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;

  return (
    <div id="invoice_graph">
      {data?.length > 0 ? (
        <LineChart
          xAxis={[
            {
              scaleType: "band",
              data: xLabels,
            //   data: xLabels,
              label: "Date",
            //   valueFormatter: (value) => value.split(" ").join("\n"),
            },
          ]}
          yAxis={[
            {
              id: "linearAxis",
              scaleType: "linear",
              label: "Order Value",
              //   valueFormatter: (value) => currencyFormatter(value),
              //   valueFormatter: (value) => currencyFormatter(value/100000)+"L",


              valueFormatter: (value) =>
                value >= 100000
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
            },
          ]}
          series={[
            {
              data: data,
            //   data: data,
              label: "Order Value",
              valueFormatter: (value) =>
                // (value == null || value == 0) ? null : value.toString(),
                value == null || value == 0
                  ? null
                  : value >= 100000
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
              color: "#007cc3",
            },
          ]}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-50px, 0)",
              fontWeight: "bold",
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              fontWeight: "bold",
              transform: "translate(0, 5px)",
              marginTop: "20px",
            },
          }}
          // width={1000}
          width={data.length < 8 ? 700 :data.length > 25?3700: data.length > 20 && data?.length <=24?3300: 2000}
          height={350}
          margin={{ left: 100 }}
        />
        ): (
        <>
          {" "}
          <div
            className={styles.noData}
            style={{ marginLeft: "-1%", marginTop: "0%" }}
          >
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Added</h4>
          </div>
        </>
      )} 
    </div>
  )
}

export default Order_value_graph