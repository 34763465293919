import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import styles from "../Styles/Table.module.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import report_loader from "../Media/ezgif.com-optimize.gif";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";

function Interested_lead_report() {

    const [selectedFromDate,setSelectedFromDate] = useState(new Date());
    const [selectedToDate,setSelectedToDate] = useState(new Date());

    const [leads_data,setLeads_Data] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [leads_load,setLeads_Load] = useState(false)

    
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leads_data?.length) : 0;

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

    const get_interested_leads = async()=>{
      setLeads_Load(true)
        try {
            let res = await fetch(`https://reportapi.equinoxlab.com/interested_leads_by_corporate_bde`,{
                method:"POST",
                headers:{"Content-Type":"Application/Json"},
                body: JSON.stringify({
                    START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
                    END_DATE:  moment(selectedToDate).format("YYYY-MM-DD")
                })
              })
            let data = await res.json();
            setLeads_Load(false)
            setLeads_Data(data?.Data);
        } catch (error) {
          setLeads_Load(false)
        }
    }

    useEffect(()=>{
     get_interested_leads();
    },[selectedFromDate])

    const handleDateChange = (new_val)=>{
        if (new_val !== null) {
          setSelectedFromDate(new_val[0]);
          setSelectedToDate(new_val[1]);
        } else {
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());   
        }
      }

   
  return (
    <>
    <MiniDrawer/>
    {leads_load?<div className='container-fluid pt-3'><img src={report_loader} id={styles.loader} /></div>:  <div className='container-fluid pt-3'>

    <Paper>
                <TableContainer id={styles.leads_tbl_Container} >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead
                      sx={{
                        backgroundColor: "#007cc3",
                        color: "#444a4f !important",
                        position: "sticky",
                        top: 0,
                        zIndex: "999",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                          colspan={3}
                        >
                          <h5
                            className="ml-2"
                            style={{
                              color: "white",
                              textAlign: "left",
                              marginLeft: "1%",
                            }}
                          >
                            {" "}
                            Interested Leads Report (Corporate Sales)
                          </h5>
                        </TableCell>
                        <TableCell colspan={2}>
                        <DateRangePicker
                            style={{marginLeft:"20%"}}
                            // defaultValue={[new Date(), new Date()]}
                            format = "dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            Lead Owner
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            Client Name
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                            Last Updated Date
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                           Mobile No
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                          Category
                          </h6>
                        </TableCell>
   
                      </TableRow>
                    </TableHead>
                    {leads_data?.length == "0" ? (
                      <TableBody>
                        {" "}
                        <TableRow>
                          <TableCell colSpan={11} className={styles.th_unit}>
                            {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                            <p style={{ textAlign: "center" }}>
                              No Data Available
                            </p>
                          </TableCell>
                          {/* </div> */}
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody id={styles.body_content}>
                        {(rowsPerPage > 0
                          ? leads_data?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : leads_data
                        )?.map((ele,index) => (
                          <TableRow>
                            <TableCell
                              sx={{
                                border:
                                  ele?.flag != "1" ? "1px solid white" : "",
                                borderLeft:
                                  ele?.flag != "1"
                                    ? "1px solid rgb(201, 201, 201);"
                                    : "",
                                borderBottom:
                                  (ele?.flag == "1" && (index != leads_data?.length-1 && index != rowsPerPage-1)) ? "1px solid white" : "",
                                borderRight: ele?.flag == "1" ? "0px" : "",
                              }}
                              className={styles.th_unit}
                            >
                              <p
                                className="p mt-2"
                                style={{
                                  textAlign: "left",
                                  display: ele?.flag == "1" ? "block" : "none",
                                }}
                              >
                                {ele.lead_owner}
                              </p>
                            </TableCell>
                          
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "left" }}
                              >
                                {ele.company_name}
                              </p>
                            </TableCell>
                          
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.last_updated_date}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.mobile_no}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.category}
                              </p>
                            </TableCell>
   
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                    {/* <TableFooter id={styles.table_footer} >
                      <TableRow sx={{height:"40px"}}>
                        <TableCell  colSpan={5} className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>Grand Total (₹)</h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>
    
                          </h5>
                        </TableCell>
                      </TableRow>
                    </TableFooter> */}
                  </Table>
                </TableContainer>
                <TablePagination
                  id={styles.interested_footer_div}
                  class="footer_div"
                  sx={{ backgroundColor: "#007cc3", color: "white" }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={leads_data?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
    </div>}
    </>
  )
}

export default Interested_lead_report