import React, { useEffect, useState } from "react";
import styles from "../Styles/Corporate_oh.module.css";
import MiniDrawer from "../components/Minidrawer";
import Select from "react-select";
import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import report_loader from "../Media/ezgif.com-optimize.gif";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  get_oh_corporate_list_data,
  get_oh_report_data,
} from "../DataStudioRedux/actions";
import { color } from "echarts";

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "14px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    textAlign:"left"
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight:"80px",
    overflowY: 'auto',
  }),

  multiValue: (styles) => {

    return {
      ...styles,
      borderRadius:"5px",
      backgroundColor:"#78C1F3",
      color:"white"
    };
  },
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? '0 0 0 1px #89CFF3' : provided.boxShadow,  // Change to your desired color
    borderColor: state.isFocused ? '#89CFF3' : provided.borderColor,         // Optional: Change border color as well
    '&:hover': {
      borderColor: '#89CFF3'  // Change border color on hover
    }
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
      color:"white",
      fontSize:"14px"
  }),
  // multiValueRemove: (styles, { data }) => ({
  //   ...styles,
  //   color: data.color,
  //   ':hover': {
  //     backgroundColor: data.color,
  //     color: 'white',
  //   },
  // }),
};
function Corporate_oh_dashboard() {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const dispatch = useDispatch();

  const [sales_load, setSales_Load] = useState(false);
  const [sales_person_data, setSales_Person_Data] = useState([]);
  const [show_Salesperson, setShow_Salesperson] = useState([]);

  const [oh_load, setOh_Load] = useState(false);
  const [oh_list_data, setOh_List_Data] = useState([]);
  const [show_oh_data, setShow_Oh_data] = useState([]);

  const [oh_report_load, setOh_Report_Load] = useState(false);
  const [oh_report_data, setOh_Report_Data] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const [params, setParams] = useState("current_month");

  const get_sales_person = async () => {
    setSales_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/corporate_salesperson`
      );
      let data = await res.json();
      setSales_Load(false);
      let dropdown_data = [];
      if (data?.Data?.length > 0) {
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data?.push({
            label: data?.Data[i]?.Salesperson,
            value: data?.Data[i]?.Salesperson,
          });
        }
        setSales_Person_Data(dropdown_data);
      }
    } catch (error) {
      setSales_Load(false);
    }
  };

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setParams("current_month");
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date());
    }
  };

  const get_total = (val) => {
    if(val == "total_samples"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_samples)
     },0)
     return total_count;
    }
    else if(val == "total_samples_value"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_samples_value)
     },0)
  return new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "planned_count"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.planned_count)
     },0)
  return total_count;
    }
    else if(val == "planned_value"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.planned_value)
     },0)
  return new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "scheduled_count"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.scheduled_count)
     },0)
  return total_count;
    }
    else if(val == "scheduled_value"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.scheduled_value)
     },0)
  return new Intl.NumberFormat("en-IN").format(total_count);
    }
    else if(val == "postponed_count"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.postponed_count)
     },0)
  return total_count;
    }
    else if(val == "collected_count"){
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.collected_count)
     },0)
  return total_count;
    }else{
      let total_count = oh_report_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.collected_value)
     },0)
  return new Intl.NumberFormat("en-IN").format(total_count);
    }
  };

  useEffect(() => {
    get_sales_person();
  }, []);

  useEffect(()=>{
    dispatch(
      get_oh_corporate_list_data(
        setOh_Load,
        setOh_List_Data,
        selectedFromDate,
        selectedToDate,
        params
      )
    );
  },[selectedFromDate,
    selectedToDate,
    params])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch(
      get_oh_report_data(
        setOh_Report_Load,
        setOh_Report_Data,
        selectedFromDate,
        selectedToDate,
        params,
        show_oh_data,
        show_Salesperson,
        signal
      )
    );
    return () => {
      controller.abort();
    };
  }, [
    selectedFromDate,
    selectedToDate,
    show_oh_data,
    show_Salesperson,
    params,
  ]);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "OH",
      label: `OH`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div
              style={{ textAlign: "left", fontSize: "12px", padding: "10px" }}
            >
              <>{row}</>
            </div>
          );
        },
      },
    },

    {
      name: "total_samples",
      label: `Total Sample`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "total_samples_value",
      label: `Total Sample Value (₹)`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <>
              {row == undefined || row == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(row)}
            </>
          );
        },
      },
    },
    {
      name: "planned_count",
      label: `Planned Count`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "planned_value",
      label: `Planned Value (₹)`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <>
              {row == undefined || row == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(row)}
            </>
          );
        },
      },
    },
    {
      name: "scheduled_count",
      label: `Scheduled Count`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "scheduled_value",
      label: `Scheduled Value (₹)`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <>
              {row == undefined || row == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(row)}
            </>
          );
        },
      },
    },
    {
      name: "postponed_count",
      label: `Postponed Count`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "collected_count",
      label: `Collected Count`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "collected_value",
      label: `Collected Value (₹)`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <>
              {row == undefined || row == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(row)}
            </>
          );
        },
      },
    },
  ];

  
  return (
    <>
      <MiniDrawer />
      {oh_load ? 
        <div className="container-fluid" style={{ paddingLeft: "0.8vw" }}>
          <img src={report_loader} id={styles.main_loader} />
        </div>
       : <div className="container-fluid" style={{ paddingLeft: "0.8vw" }}>
        <div className="mt-3" id={styles.header_tab}>
          <div
            style={{
              backgroundColor: params == "previous_month" ? "#007cc3" : "white",
              color: params == "previous_month" ? "white" : "#007cc3",
            }}
            onClick={() => setParams("previous_month")}
          >
            Previous Month
          </div>
          <div
            style={{
              backgroundColor: params == "current_month" ? "#007cc3" : "white",
              color: params == "current_month" ? "white" : "#007cc3",
            }}
            onClick={() => setParams("current_month")}
          >
            Current Month
          </div>
          <div
            style={{
              backgroundColor: params == "next_month" ? "#007cc3" : "white",
              color: params == "next_month" ? "white" : "#007cc3",
            }}
            onClick={() => setParams("next_month")}
          >
            Next Month
          </div>
        </div>
        <div className="row mt-2" id={styles.filter_container}>
          <div className={params == "current_month"?"col-3":"col-6"}></div>
          <div className="col-3">
            {/* <p>OH</p> */}
            <Select
              isMulti
              name="label"
              options={oh_list_data}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select OH"
              value={show_oh_data}
              onChange={(e) => setShow_Oh_data(e)}
              styles={customStyles}
              maxMenuHeight="180px"
            />
          </div>
          <div className="col-3">
            {/* <p>Sales Person</p> */}
            <Select
              isMulti
              name="label"
              options={sales_person_data}
              className="basic-multi-select"
              placeholder="Select Salesperson"
              classNamePrefix="select"
              value={show_Salesperson}
              onChange={(e) => setShow_Salesperson(e)}
              styles={customStyles}
              maxMenuHeight="180px"
            />
          </div>
          {params == "current_month" ? (
            <div className="col-3">
              <DateRangePicker
              style={{width:"100%"}}
                // defaultValue={[new Date(), new Date()]}
                format="dd MMM yyyy"
                value={[selectedFromDate, selectedToDate]}
                onChange={handleDateChange}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row mt-3 mb-5" id="corporate_tbl">
        {oh_report_load?<img src={report_loader} id={styles.main_loader} />:  <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>Corporate OH Details</h5>}
            data={oh_report_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              // tableBodyMaxHeight: "50vh",
              rowsPerPageOptions: [10, 30, 50, 100],
              // rowsPerPage: "",
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                      <TableCell id={styles.first_col}>
                        <p style={{ textAlign: "left" }}>Grand Total</p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("total_samples")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("total_samples_value")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("planned_count")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("planned_value")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("scheduled_count")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("scheduled_value")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("postponed_count")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("collected_count")}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {get_total("collected_value")}
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
            }}
          />}
        </div>
      </div>}
    </>
  );
}

export default Corporate_oh_dashboard;
